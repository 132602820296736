import { styled } from 'buttered';
import React from 'react';
import { VerticalCenter } from '../..';
import { classNames } from '../../core/classNames';
import { Separator } from '../menu/components/separator';
import { ComboboxItem } from './';

let Wrapper = styled('div')`
  height: 30px;
  margin: 1px 0px;
  transition: all 0.3s;
  border-radius: var(--vapor-radius-medium);
  display: flex;
  padding: 0px 10px;
  font-size: 14px;

  &.selected {
    background: var(--vapor-accent-3);
  }

  &.complex {
    height: 34px;
    padding: 0px 8px 0px 2px;
  }
`;

let Item = styled('main')``;

let Prefix = styled('aside')`
  margin-right: 7px;
`;

export let Option = ({ item, selected }: { item: ComboboxItem; selected: boolean }) => {
  if (item.type == 'separator') return <Separator />;

  return (
    <Wrapper className={classNames({ selected, complex: item.prefix })}>
      {item.prefix && (
        <Prefix>
          <VerticalCenter>{item.prefix}</VerticalCenter>
        </Prefix>
      )}

      <Item>
        <VerticalCenter>{item.contents}</VerticalCenter>
      </Item>
    </Wrapper>
  );
};
