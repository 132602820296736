import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import scrollIntoView from 'scroll-into-view-if-needed';

export type MenuState = ReturnType<typeof useMenuState>;

export interface SelectionItem {
  id: string;
  disabled?: boolean;
  component: (d: { selected: boolean }) => React.ReactNode;
}

export let useMenuState = ({ items }: { items: SelectionItem[] }) => {
  let [selected, setSelected] = useState<string>();
  let itemRefs = useRef<{ [key: string]: HTMLButtonElement }>({});

  let setItemRef = useCallback((id: string, item: HTMLButtonElement | undefined) => {
    if (!item) {
      delete itemRefs.current[id];
    } else {
      itemRefs.current[id] = item;
    }
  }, []);

  let removeItemRef = useCallback(
    (id: string) => {
      setItemRef(id, undefined);
    },
    [setItemRef]
  );

  let currentItem = useMemo(
    () => items.map((item, index) => ({ item, index })).find(i => i.item.id == selected),
    [selected, items]
  );

  let focusItem = (id: string) => {
    let ref = itemRefs.current[id];
    if (!ref) return;

    scrollIntoView(ref, { behavior: 'smooth', scrollMode: 'if-needed' });
    ref.focus();
  };

  let focusAtIndex = (index: number) => {
    let item = items[index];
    if (!item) return;

    focusItem(item.id);
  };

  let focusNeighbor = (id: string, type: 'up' | 'down') => {
    let searchItems = type == 'down' ? items : [...items].reverse();

    let itemFound = false;
    let item = searchItems.find(item => {
      if (item.id == id) {
        itemFound = true;
        return false;
      }

      if (itemFound && !item.disabled) return true;
      return false;
    });

    if (!item) return;
    focusItem(item.id);
  };

  let focusUp = () => {
    if (!currentItem) {
      return focusAtIndex(0);
    }

    if (currentItem.index == 0) {
      focusAtIndex(items.length - 1);
    }

    focusNeighbor(currentItem.item.id, 'up');
  };

  let focusDown = () => {
    if (!currentItem || currentItem.index == items.length - 1) {
      return focusAtIndex(0);
    }

    focusNeighbor(currentItem.item.id, 'down');
  };

  return {
    selected,
    setSelected,
    setItemRef,
    removeItemRef,
    focusUp,
    focusDown,
    focusAtIndex
  };
};

let Item = ({
  item,
  state,
  onClick
}: {
  item: SelectionItem;
  state: MenuState;
  onClick: (id: string) => void;
}) => {
  let ref = useRef() as React.RefObject<HTMLButtonElement>;

  useEffect(() => {
    state.setItemRef(item.id, ref.current);
  }, [ref.current, item.id]);

  useEffect(() => () => state.removeItemRef(item.id), [item.id]);

  let selected = state.selected == item.id;

  if (item.disabled) {
    return <li>{item.component({ selected })}</li>;
  }

  return (
    <li>
      <button
        style={{
          outline: 'none',
          border: 'none',
          background: 'none',
          margin: 0,
          padding: 0,
          width: '100%',
          textAlign: 'inherit'
        }}
        onClick={() => onClick(item.id)}
        ref={ref}
        onFocus={() => state.setSelected(item.id)}
        onMouseEnter={(e: any) => {
          if (selected || !ref.current) return;
          ref.current.focus();
        }}
        onKeyDown={e => {
          if (!selected) return;

          if (e.key == 'ArrowUp') {
            e.preventDefault();
            state.focusUp();
          } else if (e.key == 'ArrowDown') {
            e.preventDefault();
            state.focusDown();
          }
        }}
      >
        {item.component({ selected })}
      </button>
    </li>
  );
};

export let SelectionList = ({
  items,
  onClick,
  state: providedState
}: {
  items: SelectionItem[];
  onClick: (id: string) => unknown;
  state?: MenuState;
}) => {
  let state = useMenuState({ items: providedState ? [] : items });
  if (providedState) state = providedState;

  return (
    <ul
      style={{
        listStyle: 'none',
        padding: 0,
        margin: 0
      }}
    >
      {items.map(item => (
        <Item key={item.id} item={item} state={state} onClick={onClick} />
      ))}
    </ul>
  );
};
