import React from 'react';
import { styled } from 'buttered';
import { VerticalCenter } from '../../atoms/align/verticalCenter';
import { Spacer } from '../../atoms/position/spacer';
import { Skeleton } from '../../atoms/skeleton';
import { useSkeleton } from '../../hooks/skeleton';

let Wrapper = styled('div')`
  display: flex;
  padding: 20px 0px;

  @media screen and (max-width: 600px) {
    padding: 20px;
  }
`;

let Content = styled('div')`
  display: flex;
  flex-direction: column;
`;

let Title = styled('h3')`
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
`;

let Description = styled('p')`
  font-size: 12px;
  font-weight: 400;
  color: var(--vapor-accent-7);
  margin-top: 4px;
  line-height: 1;
`;

export let EntityField = ({
  title,
  description,
  value,
  width,
  children,
  position,
  style,
  right,
  prefix,
  suffix,
  prefixNoSpace,
  suffixNoSpace,
  skeleton
}: {
  title: React.ReactNode;
  description?: React.ReactNode;
  value?: React.ReactNode;
  width?: string | number;
  children?: React.ReactNode;
  position?: 'inline' | 'grow';
  style?: React.CSSProperties;
  right?: boolean;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  prefixNoSpace?: boolean;
  suffixNoSpace?: boolean;
  skeleton?: boolean;
}) => {
  if (!position) position = 'grow';

  let treeSkeleton = useSkeleton();

  if (!description) description = value;

  return (
    <Wrapper
      style={{
        width,
        flex: position == 'grow' && 1,
        textAlign: right ? 'right' : undefined,
        ...style
      }}
    >
      {right && <Spacer />}

      {prefix && (
        <VerticalCenter
          style={{
            marginRight: prefixNoSpace ? 0 : 10
          }}
        >
          <div>{prefix}</div>
        </VerticalCenter>
      )}

      <VerticalCenter>
        <Content>
          {children ? (
            children
          ) : (
            <>
              <Skeleton loading={skeleton || treeSkeleton} radius={4}>
                <Title>{title}</Title>
              </Skeleton>

              {description && (
                <>
                  <Spacer height={3} />
                  <Skeleton loading={skeleton || treeSkeleton} radius={4}>
                    <Description>{description}</Description>
                  </Skeleton>
                </>
              )}
            </>
          )}
        </Content>
      </VerticalCenter>

      {suffix && (
        <VerticalCenter
          style={{
            marginLeft: suffixNoSpace ? 0 : 10
          }}
        >
          <div>{suffix}</div>
        </VerticalCenter>
      )}
    </Wrapper>
  );
};
