import React from 'react';

export let getPopoverWrapperStyles = ({
  styleOverrides,
  isMobile,
  mobileType,
  popperStyles,
  zIndex,
  isOpen,
  offset,
  cssVarPrefixMobile
}: {
  styleOverrides?: React.CSSProperties;
  isMobile: boolean;
  mobileType: string;
  popperStyles: React.CSSProperties;
  zIndex: number;
  isOpen: boolean;
  offset?: number;
  cssVarPrefixMobile: string;
}) => {
  let style: React.CSSProperties = {
    zIndex,
    pointerEvents: isOpen ? 'all' : 'none'
  };

  if (isMobile) {
    if (mobileType == 'modal') {
      style = Object.assign(style, {
        position: 'fixed',
        top: `var(${cssVarPrefixMobile}-top, 35px)`,
        left: `var(${cssVarPrefixMobile}-left, 20px)`,
        right: `var(${cssVarPrefixMobile}-right, 20px)`
      });
    } else if (mobileType == 'drawer') {
      style = Object.assign(style, {
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0
      });
    }
  } else {
    style = Object.assign(
      style,
      {
        maxWidth: '100vw',
        paddingTop: typeof offset == 'number' ? offset : 7
      },
      popperStyles
    );
  }

  if (styleOverrides) style = Object.assign(style, styleOverrides);

  return style;
};
