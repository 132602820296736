import { useButton } from '@react-aria/button';
import { styled } from 'buttered';
import React, { useRef } from 'react';
import { Image } from '../image';

let Wrapper = styled('button')`
  flex-grow: 1;
  position: relative;
  padding: 0px;
  outline: none;
  background: none;
  border: none;
  overflow: hidden;
  border-radius: var(--vapor-radius-bigger);
  transition: all 0.3s;

  &:hover {
    box-shadow: var(--vapor-shadow-smaller);
    transform: translateY(-3px);

    .thumbnail {
      transform: scale(1.05);
    }
  }
`;

let ImageWrapper = styled('aside')`
  transition: all 0.3s;
`;

let Content = styled('main')`
  position: absolute;
  left: 20px;
  bottom: 20px;
  right: 20px;
  text-align: left;

  h1 {
    font-size: 20px;
    font-weight: 600;
  }

  p {
    margin-top: 3px;
    font-size: 14px;
    opacity: 0.9;
  }
`;

export let ImageButton = React.forwardRef(
  (
    props: {
      name: string;
      description?: string;
      textColor?: string;
      image: {
        label: string;
        src?: string;
        width: number;
        height: number;
      };
    } & React.ButtonHTMLAttributes<HTMLButtonElement>,
    innerRef: any
  ) => {
    let ref = useRef();
    let { buttonProps } = useButton(props as any, ref);
    let { name, description, textColor, image } = props;

    if (innerRef) innerRef.current = ref.current;

    return (
      <Wrapper {...buttonProps} ref={ref}>
        <div>
          <ImageWrapper className="thumbnail">
            <Image {...image} />
          </ImageWrapper>

          <Content style={{ color: textColor }}>
            <h1>{name}</h1>
            <p>{description}</p>
          </Content>
        </div>
      </Wrapper>
    );
  }
);
