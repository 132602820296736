import React from 'react';
import { SSRProvider } from '@react-aria/ssr';
import { OverlayProvider } from '@react-aria/overlays';
import { Toaster } from '../toast';

export let VaporProvider = ({
  ssr,
  children
}: {
  ssr?: boolean;
  children: React.ReactNode;
}) => {
  let inner = (
    <>
      <Toaster />
      <OverlayProvider>{children}</OverlayProvider>
    </>
  );

  if (ssr) return <SSRProvider>{inner}</SSRProvider>;
  return inner;
};
