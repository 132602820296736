import React from 'react';
import { keyframes, styled } from 'buttered';
import { useCss } from '../../hooks/css';

let showBlanket = keyframes`
from {
  opacity: 0;
}

to {
  opacity: 1;
}
`;

let hideBlanket = keyframes`
from {
  opacity: 1;
}

to {
  opacity: 0;
}
`;

let Wrapper = styled('div')`
  position: fixed;
  z-index: 100;
  inset: 0px;
  background: var(--vapor-blanket);
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
`;

export let Blanket = ({ isOpen, onClick }: { isOpen: boolean; onClick?: () => void }) => {
  let className = useCss(
    isOpen
      ? `
    animation: ${showBlanket} 0.3s ease forwards;
  `
      : `
    animation: ${hideBlanket} 0.3s ease forwards;
    pointer-events: none;
  `
  );

  return <Wrapper className={className} onClick={onClick} />;
};
