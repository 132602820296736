import { keyframes } from 'buttered';

let fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(6px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
`;

let fadeOut = keyframes`
  from {
    opacity: 1;
    transform: translateY(0px);
  }

  to {
    opacity: 0;
    transform: translateY(6px);
  }
`;

let fadeInBlanket = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

let fadeOutBlanket = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

export let getAnimation = (isOpen: boolean, prefix: string, isBlanket?: boolean) =>
  `${
    isOpen
      ? `var(${prefix}-fadeIn, ${isBlanket ? fadeInBlanket : fadeIn})`
      : `var(${prefix}-fadeOut, ${isBlanket ? fadeOutBlanket : fadeOut})`
  } 0.2s ease-in-out forwards`;
