import { useEffect, useState } from 'react';
import outsideClick from '@varld/outside-click';
import { usePopper } from 'react-popper';
import { Placement } from '@popperjs/core';
import { usePopoverState } from './usePopoverState';
import { useEscape } from './useEscape';

export let usePopover = (
  state: ReturnType<typeof usePopoverState>,
  placement?: Placement,
  { isMobile }: { isMobile?: boolean } = {}
) => {
  let [referenceElement, setReferenceElement] = useState(null) as any;
  let [popperElement, setPopperElement] = useState(null) as any;

  let { styles: popperStyles, attributes: popperAttributes } = usePopper(
    referenceElement,
    popperElement,
    {
      placement: placement || 'bottom'
    }
  );

  useEffect(() => {
    return outsideClick(
      [referenceElement, popperElement],
      state.close,
      () => !isMobile && state.isOpenRef.current
    );
  }, [referenceElement, popperElement, state.isOpenRef, state.close, isMobile]);

  useEscape(state.isOpen, state.close);

  return {
    popperStyles,
    popperAttributes,
    setReferenceElement,
    setPopperElement
  };
};
