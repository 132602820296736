import { styled } from 'buttered';
import { VerticalCenter } from '../align/verticalCenter';
import { ButtonVariants, ButtonSizes, ButtonState, ButtonStyle } from './types';
import { variants, loadingStyles, disabledStyles } from './variants';

let getDisplayStyle = (display: ButtonVariants) => variants[display];

let getStyleObject = (
  display: ButtonVariants | ButtonStyle,
  { isFocussed, isHovered, disabled, loading }: ButtonState
) => {
  let style = typeof display == 'string' ? getDisplayStyle(display) : display;
  if (!style) return undefined;

  if (loading) return loadingStyles;
  if (disabled) return disabledStyles;
  if (isFocussed || (isHovered && !style.hover)) return style.focus;
  if (isHovered) return style.hover;

  return style.normal;
};

export let getCss = (display: ButtonVariants | ButtonStyle, state: ButtonState) => {
  let style = getStyleObject(display, state);

  if (!style) return ``;

  return `
    background: ${style.background};
    border: solid ${style.border} 1px;
    color: ${style.color};
  `;
};

export let getHeight = (type: ButtonVariants | ButtonStyle, size: ButtonSizes) => {
  if (type == 'unstyled') return undefined;

  if (size == 'big') return 46;
  if (size == 'medium') return 36;
  if (size == 'small') return 30;
  if (size == 'smaller') return 24;

  return undefined;
};

export let Wrapper = styled('button')`
  transition: all 0.3s;
  outline: none;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
`;

export let Inner = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

export let IconWrapper = styled(VerticalCenter)`
  transition: transform 0.3s;

  figure {
    display: flex;
  }

  svg {
    height: 16px;
    width: 16px;
  }

  &.smaller {
    svg {
      height: 12px;
      width: 12px;
    }
  }
`;

export let LoadingWrapper = styled('div')`
  background: var(--vapor-background);
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s;

  & > div {
    margin: 0px auto;
  }

  &.visible {
    opacity: 1;
    pointer-events: all;
  }
`;
