import { ModalActions } from './components/actions';
import { ModalCloseButton } from './components/closeButton';
import { ModalContent } from './components/content';
import { ModalHeader } from './components/header';
import { ModalImageHeader } from './components/imageHeader';
import { ModalMenu } from './menu';
import { ModalWrapper } from './modal';

export let Modal = {
  Menu: ModalMenu,
  Wrapper: ModalWrapper,
  Header: ModalHeader,
  ImageHeader: ModalImageHeader,
  CloseButton: ModalCloseButton,
  Content: ModalContent,
  Actions: ModalActions
};
