import React from 'react';
import { createPortal } from 'react-dom';

let WRAPPER_ID = 'vapor-popover-root';

let getElement = () => {
  let element = document.getElementById(WRAPPER_ID);

  if (!element) {
    element = document.createElement('div');
    element.setAttribute('id', WRAPPER_ID);
    document.body.appendChild(element);
  }

  return element;
};

export let RenderToBody = ({ children }: { children: React.ReactElement }) => {
  if (typeof window == 'undefined') return null;
  return createPortal(children, getElement());
};
