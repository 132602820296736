import React from 'react';
import { AlertCircle } from 'react-feather';
import { styled } from 'buttered';
import { VerticalCenter } from '../align/verticalCenter';

let Wrapper = styled('section')`
  display: flex;
  color: var(--vapor-error);
`;

let Text = styled('main')``;

let Icon = styled('aside')`
  margin-right: 7px;
`;

export let Error = ({
  children,
  style,
  iconSize
}: {
  children: React.ReactNode;
  style?: React.CSSProperties;
  iconSize?: number;
}) => {
  return (
    <Wrapper role="alert" style={style}>
      <Icon>
        <VerticalCenter>
          <AlertCircle size={iconSize || 18} />
        </VerticalCenter>
      </Icon>

      <Text>
        <VerticalCenter>{children}</VerticalCenter>
      </Text>
    </Wrapper>
  );
};
