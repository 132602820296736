import { styled } from 'buttered';

export let Spacer = styled('div')<{
  height?: string | number;
  width?: string | number;
}>`
  ${p => (!p.height && !p.width ? 'flex-grow: 1; flex-shrink: 1;' : '')}
  ${p =>
    p.height
      ? `height: ${p.height.toString() + (typeof p.height == 'number' ? 'px' : '')};`
      : ''}
  ${p =>
    p.width ? `width: ${p.width.toString() + (typeof p.width == 'number' ? 'px' : '')};` : ''}
`;
