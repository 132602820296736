import React, { useRef } from 'react';
import { ChevronRight } from 'react-feather';
import { useButton } from '@react-aria/button';
import { styled } from 'buttered';
import { VerticalCenter } from '../../atoms/align/verticalCenter';
import { Spacer } from '../../atoms/position/spacer';
import { Text } from '../../atoms/text';

let Wrapper = styled('button')`
  color: unset;
  cursor: pointer;
  transition: all 0.3s;
  border-radius: 12px;
  padding: 15px;
  width: 100%;
  border: none;
  background: none;
  outline: none;
  flex: 1;

  & > div {
    display: flex;
  }

  h3 {
    font-size: 18px;
    font-weight: 600;
    display: flex;
    margin: 0px 0px 4px 0px;

    svg {
      height: 12px;
      width: 12px;
      margin: 2px 0px 0px 4px;
    }
  }

  p {
    margin: 0px;
    font-size: 14px;
    color: var(--vapor-accent-6);
    text-align: left;
  }

  &:hover,
  &:focus {
    background: var(--vapor-accent-1);

    .arrow {
      color: var(--vapor-foreground);
      transform: translateX(0px);
    }
  }
`;

let Arrow = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all 0.3s;
  color: transparent;
  transform: translateX(-10px);

  svg {
    height: 16px;
    width: 16px;
    stroke-width: 1.5px;
  }
`;

let Icon = styled('div')`
  height: 64px;
  width: 64px;
  background: var(--vapor-primary);
  border-radius: 10px;
  margin-right: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;

  svg,
  img {
    color: var(--vapor-primary-text);
    height: 30px;
    width: 30px;
    margin: 0px auto;
    stroke-width: 1.5px;
  }
`;

export let ActionButton = React.forwardRef(
  (
    props: {
      name: string;
      description: string;
      color: string;
      icon: React.ReactElement;
    } & React.ButtonHTMLAttributes<HTMLButtonElement>,
    innerRef: any
  ) => {
    let ref = useRef();
    let { buttonProps } = useButton(props as any, ref);
    let { name, description, color, icon } = props;

    if (innerRef) innerRef.current = ref.current;

    return (
      <Wrapper {...buttonProps} ref={ref}>
        <div>
          <Icon style={{ background: color }}>{icon}</Icon>

          <div>
            <VerticalCenter>
              <div>
                <h3>{name}</h3>
                <Text truncate={2}>{description}</Text>
              </div>
            </VerticalCenter>
          </div>

          <Spacer />

          <Arrow className="arrow">
            <ChevronRight />
          </Arrow>
        </div>
      </Wrapper>
    );
  }
);
