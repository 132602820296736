import { useBreadcrumbs, useBreadcrumbItem } from '@react-aria/breadcrumbs';
import React, { useRef } from 'react';
import { classNames } from '../../core/classNames';
import { styled } from 'buttered';

let Wrapper = styled('nav')`
  width: fit-content;
`;

let List = styled('ol')`
  display: flex;
  list-style: none;
  margin: 0px;
  padding: 0px;
  font-size: 14px;
  width: fit-content;
`;

export let Breadcrumbs = (props: { children: any[] }) => {
  let { navProps } = useBreadcrumbs(props);
  let children = React.Children.toArray(props.children);

  return (
    <Wrapper {...navProps}>
      <List>
        {children.map((child, i) =>
          React.cloneElement(child as any, { isCurrent: i === children.length - 1 })
        )}
      </List>
    </Wrapper>
  );
};

let Item = styled('span')`
  color: var(--vapor-accent-6);
  cursor: pointer;

  &.isCurrent {
    color: var(--vapor-foreground);
  }
`;

let Separator = styled('span')`
  padding: 0px 8px;
  color: var(--vapor-accent-5);
`;

export let BreadcrumbItem = (props: { isCurrent: boolean; children: React.ReactNode }) => {
  let ref = useRef();
  let { itemProps } = useBreadcrumbItem({ ...props, elementType: 'span' }, ref);

  return (
    <li>
      <Item {...itemProps} ref={ref} className={classNames({ isCurrent: props.isCurrent })}>
        {props.children}
      </Item>

      {!props.isCurrent && <Separator aria-hidden="true">{'/'}</Separator>}
    </li>
  );
};
