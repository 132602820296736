import { keyframes, styled } from 'buttered';
import React, { useMemo } from 'react';
import { useProgressBar } from '@react-aria/progress';
import { Spacer } from '../position/spacer';

let pulse = keyframes`
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.6;
  }
  
  100% {
    opacity: 1;
  }
`;

let DEFAULT_SIZE = 6;

let Wrapper = styled('div')`
  display: flex;
  width: fit-content;
`;

let Dot = styled('div')<{ size: number; delay?: string }>`
  animation: ${pulse} 1.5s ease infinite;
  border-radius: 50%;
  height: ${p => p.size}px;
  width: ${p => p.size}px;
  background: var(--vapor-accent-4);
  ${p => (p.delay ? `animation-delay: ${p.delay}s;` : '')}
`;

export let Dots = ({ size }: { size?: number }) => {
  let spaceBetween = useMemo(() => (size || DEFAULT_SIZE) * 0.5, [size]);

  let { progressBarProps } = useProgressBar({
    isIndeterminate: true,
    'aria-label': 'Loading...'
  });

  return (
    <Wrapper {...progressBarProps}>
      <Dot size={size || DEFAULT_SIZE} />
      <Spacer width={spaceBetween} />
      <Dot size={size || DEFAULT_SIZE} delay="0.5" />
      <Spacer width={spaceBetween} />
      <Dot size={size || DEFAULT_SIZE} delay="1" />
    </Wrapper>
  );
};
