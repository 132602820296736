import React from 'react';
import clsx from 'clsx';
import { keyframes } from 'buttered';
import useDelayed from 'use-delayed';
import { classNames } from '../../core/classNames';
import { styled } from 'buttered';

let Wrapper = styled('div')`
  position: relative;

  &.fit {
    height: fit-content;
    width: fit-content;
  }
`;

let animation = keyframes`
  0% {
    background-position: 100%;
  }
  40%, 100% {
    background-position: -100%;
  }
`;

let SkeletonLoading = styled('div')`
  position: absolute;
  z-index: 1;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s;
  background-image: linear-gradient(90deg, #f4f4f4 0px, #eaeaea 40px, #f4f4f4 80px);
  background-size: 200%;

  &.animate {
    opacity: 1;
    pointer-events: all;
    animation: ${animation} 2s infinite ease-out;
  }
`;

export let Skeleton = ({
  children,
  radius,
  loading,
  style,
  full
}: {
  children: React.ReactNode;
  radius?: string | number;
  loading?: boolean;
  style?: React.CSSProperties;
  full?: boolean;
}) => {
  let visible = useDelayed(loading, 300, [true]);
  if (!visible) return <>{children}</>;

  return (
    <Wrapper style={style} className={classNames({ fit: !full })}>
      <div
        style={{
          width: !full && 'fit-content',
          opacity: loading ? 0 : 1
        }}
      >
        {children}
      </div>

      <SkeletonLoading
        className={clsx({ animate: loading })}
        style={{ borderRadius: radius }}
      />
    </Wrapper>
  );
};
