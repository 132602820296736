import React from 'react';
import { styled } from 'buttered';
import { classNames } from '../../core/classNames';
import { useImage } from '../../hooks/image';
import { useSkeleton } from '../../hooks/skeleton';
import { Skeleton } from '../skeleton';

let Wrapper = styled('div')`
  background: var(--vapor-accent-1);
  overflow: hidden;
  display: flex;
`;

let Inner = styled('div')`
  transition: all 0.3s;
  opacity: 1;
  display: flex;

  &.loading {
    opacity: 0;
  }
`;

export let Avatar = ({
  src,
  name,
  size,
  skeleton,
  radius
}: {
  src?: string;
  name: string;
  size: number;
  skeleton?: boolean;
  radius?: string;
}) => {
  if (!radius) radius = '50%';

  let [image, { loaded }] = useImage({
    src,
    label: name,
    style: {
      borderRadius: radius
    }
  });

  let treeSkeleton = useSkeleton();

  return (
    <Skeleton loading={skeleton || treeSkeleton} radius={radius}>
      <Wrapper
        style={{ height: size, width: size, borderRadius: radius }}
        aria-label={loaded ? undefined : 'Loading...'}
      >
        <Inner className={classNames({ loading: !loaded })}>{image}</Inner>
      </Wrapper>
    </Skeleton>
  );
};
