import React, { useEffect, useRef, useState } from 'react';

let getWrapperStyles = (sized: boolean) => {
  let style: React.CSSProperties = {
    display: 'flex'
  };

  if (sized) {
    style = Object.assign(style, {
      height: 0,
      overflow: 'hidden',
      position: 'relative'
    });
  }

  return style;
};

let getInnerStyles = (sized: boolean) => {
  let style: React.CSSProperties = {
    display: 'flex'
  };

  if (sized) {
    style = Object.assign(style, {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%'
    });
  }

  return style;
};

let imageStyles = {
  height: '100%',
  width: '100%',
  objectFit: 'cover'
};

export let useImage = ({
  label,
  src,
  width,
  height,
  style,
  timeout
}: {
  label: string;
  src?: string;
  width?: number;
  height?: number;
  style?: React.CSSProperties;
  timeout?: number;
}): [JSX.Element, { loaded: boolean; imageRef: React.RefObject<HTMLImageElement> }] => {
  let [loaded, setLoaded] = useState(false);
  let imageRef = useRef() as React.RefObject<HTMLImageElement>;

  useEffect(() => {
    if (!imageRef.current) return;

    if (imageRef.current.complete) setLoaded(true);
  }, [imageRef.current]);

  useEffect(() => {
    setLoaded(false);
    let to = setTimeout(() => setLoaded(true), timeout || 1200);
    return () => clearTimeout(to);
  }, [src]);

  let sized = !!(height && width);

  let image = (
    <figure
      style={{
        paddingTop: height && width ? `${(height / width) * 100}%` : undefined,
        ...getWrapperStyles(sized)
      }}
    >
      <main style={getInnerStyles(sized)}>
        {src && (
          <img
            src={src}
            alt={label}
            onLoad={() => setLoaded(true)}
            style={Object.assign({}, imageStyles, style)}
          />
        )}
      </main>
    </figure>
  );

  return [image, { loaded, imageRef }];
};
