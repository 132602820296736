import { keyframes } from 'buttered';

export let showModalCenteredKeyframes = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
`;

export let hideModalCenteredKeyframes = keyframes`
  0% {
    opacity: 1;
    transform: translateY(0px);
  }

  100% {
    opacity: 0;
    transform: translateY(20px);
  }
`;

export let showModalDrawerKeyframes = keyframes`
  0% {
    opacity: 0;
    bottom: -26vh;
  }

  100% {
    opacity: 1;
    bottom: 0vh;
  }
`;

export let hideModalDrawerKeyframes = keyframes`
  0% {
    opacity: 1;
    bottom: 0vh;
  }

  100% {
    opacity: 0;
    bottom: -40%;
  }
`;

export let showModalSidebarKeyframes = keyframes`
  0% {
    opacity: 0;
    transform: translateX(50px);
  }

  100% {
    opacity: 1;
    transform: translateX(0px);
  }
`;

export let hideModalSidebarKeyframes = keyframes`
  0% {
    opacity: 1;
    transform: translateX(0px);
  }

  100% {
    opacity: 0;
    transform: translateX(50px);
  }
`;
