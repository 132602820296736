import React from 'react';
import { Skeleton } from '../../atoms/skeleton';
import { styled } from 'buttered';
import { useSkeleton } from '../../hooks/skeleton';

let Wrapper = styled('div')`
  display: flex;

  h3 {
    font-size: 18px;
    font-weight: 600;
    display: flex;
    margin: 0px 0px 6px 0px;
    line-height: 1;

    /* svg {
      height: 12px;
      width: 12px;
      margin: 2px 0px 0px 4px;
    } */
  }

  p {
    margin: 0px;
    font-size: 15px;
    line-height: 1;
  }
`;

let Inner = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

let Icon = styled('div')`
  height: 64px;
  width: 64px;
  background: #45aaf2;
  border-radius: 10px;
  margin-right: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  svg {
    color: white;
    height: 30px;
    width: 30px;
    margin: 0px auto;
    stroke-width: 1.5px;
  }
`;

export let ValueCard = ({
  name,
  value,
  color,
  icon
}: {
  name: string;
  value?: string | number;
  color?: string;
  icon?: React.ReactElement;
}) => {
  let skeleton = useSkeleton();

  return (
    <Skeleton
      radius={10}
      loading={typeof value == 'undefined' || skeleton}
      style={{ width: '100%' }}
    >
      <Wrapper>
        {color && icon && <Icon style={{ background: color }}>{icon}</Icon>}
        <Inner>
          <div>
            <h3>
              <span>{name}</span>
            </h3>
            <p>{typeof value == 'undefined' ? 100 : value}</p>
          </div>
        </Inner>
      </Wrapper>
    </Skeleton>
  );
};
