import React, { useCallback, useEffect } from 'react';
import { Placement } from '@popperjs/core';
import { FocusScope } from '@react-aria/focus';
import { RenderToBody } from './components/portal';
import { Triggers, useTrigger } from './hooks/useTrigger';
import { useId } from './hooks/useId';
import { usePopoverState } from './hooks/usePopoverState';
import { usePopover } from './hooks/usePopover';
import { Blanket } from './components/blanket';
import { MobileHeader } from './components/mobileHeader';
import { getPopoverWrapperStyles } from './styles/getPopoverWrapperStyles';
import { getPopoverStyles } from './styles/getPopoverStyles';

let cssVarPrefix = '--vapor-popover';
let cssVarPrefixMobile = '--vapor-popover-mobile';

export let Popover = ({
  popover,
  children,
  classNames,
  styles,
  placement,
  offset,
  triggerRef,
  state: providedState,
  triggers,
  unstyled,
  mobile,
  disableFocusScope
}: {
  popover: (d: { id: string; state: ReturnType<typeof usePopoverState> }) => React.ReactNode;
  triggerRef?: React.RefObject<HTMLElement>;
  children?: (data: {
    attrs: React.HTMLAttributes<HTMLElement> & {
      'data-popover-anchor': string;
    };
    ref: React.RefObject<any>;
  }) => React.ReactNode;
  classNames?: {
    popover?: string;
    popoverMobileInner?: string;
    popoverInner?: string;
  };
  styles?: {
    popover?: React.CSSProperties;
    popoverMobileInner?: React.CSSProperties;
    popoverInner?: React.CSSProperties;
  };
  offset?: number;
  state?: ReturnType<typeof usePopoverState>;
  triggers?: Triggers[];
  placement?: Placement;
  unstyled?: boolean;
  disableFocusScope?: boolean;
  mobile?:
    | boolean
    | {
        title?: string;
        closeButton?: boolean;
        type?: 'drawer' | 'modal';
      };
}) => {
  if (!triggers) triggers = ['click'];

  let triggerId = useId();
  let popoverId = useId();
  let state = usePopoverState();
  if (providedState) state = providedState;

  let { isOpen, isVisible, close, open, zIndex, isSmallScreen } = state;

  let popoverEl = popover({ state, id: triggerId });

  let triggerHandler = useCallback(
    ({ intent }) => {
      if (!intent) {
        if (isOpen) intent = 'close';
        else intent = 'open';
      }

      if (intent == 'open') open();
      if (intent == 'close') close();
    },
    [isOpen, close, open]
  );

  let listeners = useTrigger(triggers, triggerHandler);

  let isMobile = isSmallScreen && !!mobile;
  let mobileType = 'modal';
  if (typeof mobile == 'object' && mobile.type == 'drawer') mobileType = 'drawer';

  let {
    popperStyles,
    popperAttributes,
    setPopperElement,
    setReferenceElement
  } = usePopover(state, placement, { isMobile });

  useEffect(() => {
    if (children || !triggerRef) return;
    setReferenceElement(triggerRef.current);
  }, [children, triggerRef]);

  let inner = isVisible && (
    <div
      className={classNames?.popover}
      ref={setPopperElement}
      id={popoverId}
      style={getPopoverWrapperStyles({
        isMobile,
        mobileType,
        isOpen,
        popperStyles: popperStyles.popper,
        zIndex,
        offset,
        styleOverrides: styles?.popover,
        cssVarPrefixMobile
      })}
      {...popperAttributes.popper}
      role="region"
      aria-labelledby={triggerId}
    >
      <main
        className={
          (classNames?.popoverInner ? classNames?.popoverInner : '') +
          (isMobile && classNames?.popoverMobileInner
            ? ' ' + classNames?.popoverMobileInner
            : '')
        }
        style={getPopoverStyles({
          isMobile,
          isOpen,
          cssVarPrefix,
          cssVarPrefixMobile,
          unstyled,
          mobileType,
          styleOverrides: styles?.popoverInner,
          styleOverridesMobile: styles?.popoverMobileInner
        })}
      >
        {isMobile ? (
          <>
            {typeof mobile == 'object' && mobile.title && (
              <MobileHeader
                cssVarPrefix={cssVarPrefixMobile}
                title={mobile.title}
                close={close}
                closeButton={mobile.closeButton !== false}
              />
            )}

            <div
              style={{
                padding: `var(${cssVarPrefixMobile}-padding, 20px)`
              }}
            >
              {popoverEl}
            </div>
          </>
        ) : (
          popoverEl
        )}
      </main>
    </div>
  );

  return (
    <>
      {children &&
        children({
          ref: setReferenceElement,
          attrs: {
            ...listeners,
            id: triggerId,
            'data-popover-anchor': 'true',
            role: 'button',
            'aria-expanded': isOpen ? 'true' : 'false',
            'aria-haspopup': 'true',
            'aria-owns': popoverId
          }
        })}

      {isVisible && (
        <RenderToBody>
          <>
            {isMobile && (
              <Blanket
                zIndex={zIndex - 1}
                visible={isOpen}
                onClick={state.delayedIsOpen ? state.close : undefined}
              />
            )}

            {disableFocusScope ? (
              inner
            ) : (
              <FocusScope restoreFocus={isOpen} autoFocus={isOpen}>
                {inner}
              </FocusScope>
            )}
          </>
        </RenderToBody>
      )}
    </>
  );
};
