import { styled } from 'buttered';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ChevronDown, Search, X } from 'react-feather';
import { useMeasure, useWindowSize } from 'react-use';
import { VerticalCenter } from '../../atoms/align/verticalCenter';
import { Label } from '../../atoms/input';
import { SelectionList, useMenuState } from '../menu/internal/selectionList';
import { Popover, usePopoverState } from '../popover';
import { Option } from './item';

let Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
`;

let InputWrapper = styled('div')`
  border: var(--vapor-border);
  border-radius: var(--vapor-radius-medium);
  display: flex;
`;

let Input = styled('input')`
  height: 34px;
  padding: 5px 0px;
  flex-grow: 1;
  border: none;
  outline: none;
  background: none;
  font-size: 14px;
`;

let IconWrapper = styled('div')`
  flex-shrink: 0;
  width: 34px;

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  svg {
    height: 14px;
    width: 14px;
  }
`;

let ListWrapper = styled('div')`
  padding: 10px;
  overflow: auto;
  -webkit-font-smoothing: antialiased;
`;

let Empty = styled('div')`
  text-align: center;
  padding: 10px;
`;

export interface ComboboxItem {
  id: string;
  searchHelper?: string;
  type?: 'item' | 'separator';
  disabled?: boolean;
  contents: string;
  prefix?: React.ReactNode;
}

export let Combobox = ({
  placeholder,
  items,
  label,
  onSelect,
  emptyText,
  value,
  height
}: {
  placeholder?: string;
  items: ComboboxItem[];
  label?: string;
  onSelect: (id: string | undefined) => unknown;
  emptyText?: string;
  value?: string | null;
  height?: number;
}) => {
  let [search, setSearch] = useState('');
  let popoverState = usePopoverState();
  let [selectedId, setSelectedId] = useState<string | null | undefined>();
  let [hasFocus, setHasFocus] = useState(false);

  if (value !== undefined) selectedId = value;

  let currentItems = useMemo(() => {
    let currentSearch = search.trim().toLowerCase();
    if (currentSearch.length == 0) return items;

    return items.filter(item => {
      if (item.searchHelper && item.searchHelper.toLowerCase().includes(currentSearch))
        return true;

      if (item.contents) return item.contents.toLowerCase().includes(currentSearch);

      return false;
    });
  }, [items, search]);

  let triggerRef = useRef() as React.RefObject<HTMLDivElement>;
  let inputRef = useRef() as React.RefObject<HTMLInputElement>;

  let [ref, { width }] = useMeasure();
  let { height: windowHeight } = useWindowSize();

  useEffect(() => ref(triggerRef.current), [ref, triggerRef.current]);

  let maxHeight = useMemo(() => {
    if (!triggerRef.current) return windowHeight * 0.3;
    let rect = triggerRef.current?.getBoundingClientRect();

    let maxHeight = windowHeight - rect.top - rect.height - 40;

    return maxHeight > 300 ? 300 : maxHeight;
  }, [triggerRef.current, width, popoverState.open]);

  let menuItems = useMemo(
    () =>
      currentItems.map(i => ({
        id: i.id,
        disabled: i.disabled || i.type == 'separator',
        component: ({ selected }) => <Option selected={selected} item={i} />
      })),
    [currentItems]
  );

  let menuState = useMenuState({
    items: menuItems
  });

  let selectedItem = useMemo(
    () => (selectedId ? items.find(i => i.id == selectedId) : undefined),
    [selectedId, items]
  );

  useEffect(() => {
    if (!popoverState.isOpen && inputRef.current && !popoverState.isSmallScreen)
      inputRef.current.blur();
  }, [popoverState.isOpen, inputRef.current, popoverState.isSmallScreen]);

  return (
    <Wrapper>
      {label && <Label>{label}</Label>}

      <InputWrapper ref={triggerRef}>
        <IconWrapper>
          <VerticalCenter>
            <Search />
          </VerticalCenter>
        </IconWrapper>

        <Input
          ref={inputRef}
          value={hasFocus ? search : selectedItem?.contents || ''}
          onChange={e => {
            setSearch(e.target.value);
            setHasFocus(true);
          }}
          placeholder={placeholder || 'Search...'}
          onFocus={() => {
            popoverState.open();

            if (popoverState.isSmallScreen) {
              inputRef.current.blur();
            }
          }}
          onBlur={() => setHasFocus(false)}
          onKeyDown={e => {
            if (e.key == 'ArrowDown') menuState.focusAtIndex(0);
            if (e.key == 'Enter') menuState.focusAtIndex(0);
          }}
          style={{
            height
          }}
        />

        {!popoverState.isSmallScreen && (
          <IconWrapper
            onClick={() => {
              if (selectedItem) {
                setSearch('');
                setSelectedId(undefined);
                if (onSelect) onSelect(undefined);
              } else {
                if (inputRef.current) inputRef.current.focus();
              }
            }}
          >
            <VerticalCenter>{selectedItem ? <X /> : <ChevronDown />}</VerticalCenter>
          </IconWrapper>
        )}
      </InputWrapper>

      <Popover
        disableFocusScope
        popover={() => (
          <div style={{ width: !popoverState.isSmallScreen && `min(90vh, ${width}px)` }}>
            {currentItems.length == 0 ? (
              <Empty>{emptyText || 'No items found 🕵️‍♀️'}</Empty>
            ) : (
              <ListWrapper style={{ maxHeight }}>
                <SelectionList
                  state={menuState}
                  onClick={id => {
                    popoverState.close();
                    setSelectedId(id);
                    if (onSelect) onSelect(id);
                  }}
                  items={menuItems}
                />
              </ListWrapper>
            )}
          </div>
        )}
        offset={2}
        triggerRef={triggerRef}
        state={popoverState}
        styles={{
          popoverInner: {
            padding: 0,
            boxShadow: 'var(--vapor-shadow-smaller)',
            border: 'var(--vapor-border)',
            borderRadius: 'var(--vapor-radius-bigger)'
          }
        }}
        mobile={{
          type: 'drawer'
        }}
      ></Popover>
    </Wrapper>
  );
};
