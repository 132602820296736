import React from 'react';
import { styled } from 'buttered';
import { VerticalCenter } from '../align/verticalCenter';

let Wrapper = styled('div')`
  border-radius: var(--vapor-radius-bigger);
  padding: 20px;
  display: flex;
  color: var(--vapor-accent-8);
  font-size: 14px;
  background: var(--vapor-accent-1);

  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
`;

let Content = styled('main')``;

let Label = styled('label')`
  flex-shrink: 0;
  height: 24px;
  line-height: 24px;
  margin-right: 10px;
  padding: 0px 8px;
  border-radius: var(--vapor-radius-small);
  font-size: 14px;
  width: fit-content;

  &.plain {
    background: var(--vapor-foreground);
    color: var(--vapor-background);
  }

  &.notice {
    background: var(--vapor-primary);
    color: var(--vapor-primary-text);
  }

  &.warning {
    background: var(--vapor-yellow);
    color: var(--vapor-accent-10);
  }

  &.error {
    background: var(--vapor-error);
    color: var(--vapor-error-text);
  }

  @media screen and (max-width: 500px) {
    margin-bottom: 6px;
  }
`;

export let Notice = ({
  children,
  variant,
  label
}: {
  children: React.ReactNode;
  variant?: 'notice' | 'error' | 'warning' | 'plain';
  label?: string;
}) => {
  if (!variant) variant = 'notice';
  if (!label) {
    label = 'Note';

    if (variant == 'error') label = 'Error';
    if (variant == 'warning') label = 'Warning';
  }

  return (
    <Wrapper>
      <Label role="label" className={variant}>
        {label}
      </Label>
      <Content role="alert">
        <VerticalCenter>
          <div>{children}</div>
        </VerticalCenter>
      </Content>
    </Wrapper>
  );
};
