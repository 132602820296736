import React from 'react';
import { useIsSSR } from '@react-aria/ssr';
import { css, styled } from 'buttered';
import useMedia from 'use-media';
import { VerticalCenter } from '../../atoms/align/verticalCenter';
import { classNames } from '../../core/classNames';
import { EntityMenu } from './menu';
import { MenuItem } from '../../molecules/menu/internal/wrapper';

let Wrapper = styled('main')`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
  justify-content: flex-start;
  align-items: stretch;
  padding: 0px 20px;

  @media screen and (max-width: 600px) {
    padding: 0px;
    flex-direction: column;
    flex-wrap: nowrap;
  }
`;

let MenuWrapper = styled('div')`
  flex-shrink: 0;

  @media screen and (max-width: 600px) {
    margin-right: 15px;

    &.mobileHidden {
      display: none;
    }
  }
`;

let mobileBorderStyle = css`
  @media screen and (max-width: 600px) {
    border-bottom: var(--vapor-border);
  }
`;

let ContentEntityWrapper = styled('div')`
  display: flex;
  flex: 1;
`;

let ContentEntity = ({ children, last }: { children: React.ReactNode; last: boolean }) => {
  return (
    <ContentEntityWrapper className={classNames(!last ? mobileBorderStyle : undefined)}>
      {children}
    </ContentEntityWrapper>
  );
};

export let EntityContent = ({
  children,
  menu
}: {
  children: React.ReactNode;
  menu?: {
    action: (id: string) => unknown;
    items: MenuItem[];
  };
}) => {
  let isServer = useIsSSR();

  let isMobile = useMedia({ maxWidth: '600px' });

  let childArray = Array.isArray(children) ? children : [children];

  let menuElement = menu && (
    <MenuWrapper className={classNames({ mobileHidden: isServer })}>
      <VerticalCenter>
        <EntityMenu {...menu} />
      </VerticalCenter>
    </MenuWrapper>
  );

  return (
    <Wrapper>
      {childArray.map((child, i) => (
        <ContentEntity key={i} last={i == childArray.length - 1}>
          {child}
          {i == 0 && !isServer && isMobile && menuElement}
        </ContentEntity>
      ))}

      {(!isMobile || isServer) && menuElement}
    </Wrapper>
  );
};
