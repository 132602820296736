import React, { useEffect } from 'react';
import { styled } from 'buttered';
import { useId } from '@react-aria/utils';
import scrollIntoView from 'scroll-into-view-if-needed';
import { PADDING, WIDTH } from './constants';
import { Wrapper, Indicator, List } from './styled';
import { SwitchItem } from './components';
import { useSwitch } from '../../hooks/switch';

let OverflowBox = styled('div')`
  overflow: auto;
`;

export let Switch = ({
  state,
  label
}: {
  state: ReturnType<typeof useSwitch>;
  label?: string;
}) => {
  let id = useId();

  useEffect(() => {
    if (!state.selectedOption?.option.id || typeof window == 'undefined') return;
    let key = state.selectedOption?.option.id;
    let node = document.getElementById(`_vpr_sw_${id}_${key}`);

    if (node) {
      scrollIntoView(node, { behavior: 'smooth', scrollMode: 'if-needed' });
    }
  }, [state.selectedOption?.option.id]);

  return (
    <OverflowBox>
      <Wrapper>
        <List>
          {state.options.map(item => (
            <SwitchItem
              item={item}
              onAction={state.setOption}
              id={id}
              key={item.id}
              isSelected={state.selectedOption?.option?.id == item.id}
            />
          ))}
        </List>

        <Indicator
          style={
            state.selectedOption
              ? { left: PADDING + state.selectedOption.index * WIDTH }
              : { opacity: 0, left: PADDING }
          }
        />
      </Wrapper>
    </OverflowBox>
  );
};
