import React from 'react';
import { styled } from 'buttered';

let ItemWrapper = styled('div')`
  padding: 8px 12px;
  font-size: 13px;
  outline: none;
  cursor: pointer;
  border-radius: var(--vapor-radius-medium);
  transition: all 0.2s;
  color: var(--vapor-accent-8);

  @media screen and (max-width: 500px) {
    padding: 8px 14px;
    font-size: 17px;
  }
`;

export let Option = ({
  children,
  selected
}: {
  children: React.ReactNode;
  selected: boolean;
}) => {
  return (
    <ItemWrapper
      style={{
        background: selected && 'var(--vapor-accent-2)'
      }}
    >
      {children}
    </ItemWrapper>
  );
};
