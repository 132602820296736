import { useEffect } from 'react';

export let useEscape = (enabled: boolean, callback: () => unknown) => {
  useEffect(() => {
    if (!enabled) return;

    let handler = (e: KeyboardEvent) => {
      if (e.key == 'Escape') callback();
    };

    window.addEventListener('keyup', handler);
    return () => window.removeEventListener('keyup', handler);
  }, [enabled, callback]);
};
