import { useFocus } from '@react-aria/interactions';
import { mergeProps, useId } from '@react-aria/utils';
import React, { useState } from 'react';
import { styled } from 'buttered';
import { classNames } from '../../core/classNames';
import { VerticalCenter } from '../align/verticalCenter';
import { Error } from '../text/error';

let FONTSIZE = '14px';

let Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export let Label = styled('label')`
  font-size: 13px;
  margin-bottom: 6px;
  font-weight: 500;
`;

let Description = styled('p')`
  font-size: 12px;
  color: var(--vapor-accent-8);
  margin-bottom: 6px;
`;

let Extra = styled('div')`
  margin-top: 6px;
`;

let StyledInput = styled('input')`
  border: none;
  outline: none;
  background: transparent;
  height: 34px;
  padding: 0px 12px;
  font-size: ${FONTSIZE};
  flex-grow: 1;
`;

let InputWrapper = styled('main')`
  display: flex;
  overflow: hidden;

  &:not(.noBorder) {
    border: var(--vapor-border);
    border-radius: var(--vapor-radius-medium);
    transition: all 0.3s;

    &.focussed {
      border: var(--vapor-border-highlight);
    }
  }
`;

let Suffix = styled('aside')`
  background: var(--vapor-accent-1);
  border-left: solid var(--vapor-accent-2) 1px;
  padding: 0px 10px;
  color: var(--vapor-accent-7);
  font-size: ${FONTSIZE};
`;

let Prefix = styled('aside')`
  background: var(--vapor-accent-1);
  border-right: solid var(--vapor-accent-2) 1px;
  padding: 0px 10px;
  color: var(--vapor-accent-7);
  font-size: ${FONTSIZE};
`;

export let Input = React.forwardRef(
  (
    props: {
      label: string;
      hideLabel?: boolean;
      width?: string;
      prefix?: React.ReactNode;
      suffix?: React.ReactNode;
      error?: React.ReactNode;
      description?: React.ReactNode;
      noBorder?: boolean;
      style?: {
        wrapper?: React.CSSProperties;
        input?: React.CSSProperties;
        inputOuter?: React.CSSProperties;
      };
    } & React.InputHTMLAttributes<HTMLInputElement>,
    innerRef: any
  ) => {
    let labelId = useId();
    let descriptionId = useId();

    let style = props.style || {};

    let [focussed, setFocussed] = useState(false);

    let { focusProps } = useFocus({
      onFocusChange: setFocussed
    });

    return (
      <Wrapper style={{ ...(style.wrapper || {}), width: props.width }}>
        {!props.hideLabel && <Label id={labelId}>{props.label}</Label>}

        {props.description && (
          <Description id={descriptionId}>{props.description}</Description>
        )}

        <InputWrapper
          style={style.inputOuter}
          className={classNames({ focussed, noBorder: props.noBorder })}
        >
          {props.prefix && (
            <Prefix>
              <VerticalCenter>{props.prefix}</VerticalCenter>
            </Prefix>
          )}

          <StyledInput
            style={style.input}
            {...mergeProps(props, focusProps)}
            ref={innerRef}
            aria-describedby={!props.description ? descriptionId : undefined}
            aria-labelledby={!props.hideLabel ? labelId : undefined}
            aria-label={props.hideLabel ? props.label : undefined}
          />

          {props.suffix && (
            <Suffix>
              <VerticalCenter>{props.suffix}</VerticalCenter>
            </Suffix>
          )}
        </InputWrapper>

        {props.error && (
          <Extra>
            <Error style={{ fontSize: 13 }} iconSize={16}>
              {props.error}
            </Error>
          </Extra>
        )}
      </Wrapper>
    );
  }
);
