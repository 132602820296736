import React from 'react';
import { styled } from 'buttered';
import { Skeleton } from '../../atoms/skeleton';
import { useSkeleton } from '../../hooks/skeleton';

let Wrapper = styled('header')`
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
  justify-content: flex-start;
  align-items: stretch;
  border-bottom: var(--vapor-border);
  font-size: 16px;
  font-weight: 600;
`;

export let EntityHeader = ({ children }: { children: React.ReactNode }) => {
  let skeleton = useSkeleton();

  return (
    <Wrapper>
      <Skeleton loading={skeleton} radius={6}>
        {children}
      </Skeleton>
    </Wrapper>
  );
};
