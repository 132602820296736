import { useState } from 'react';

export let useToggle = (def?: boolean) => {
  let [isSelected, setSelected] = useState(() => !!def);

  let set = (v: boolean) => setSelected(v);
  let toggle = () => setSelected(!isSelected);

  return {
    isSelected,
    set,
    toggle
  };
};
