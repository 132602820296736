import React from 'react';
import { X } from 'react-feather';
import { Button } from '../../../atoms/button';
import { useModalContext } from '../context';

export let ModalCloseButton = ({ inline }: { inline?: boolean }) => {
  let { onClose } = useModalContext();

  let inner = <Button icon={<X />} size="small" onClick={onClose}></Button>;

  if (inline) return inner;

  return (
    <div
      style={{
        position: 'absolute',
        top: 15,
        left: 15
      }}
    >
      {inner}
    </div>
  );
};
