import { styled } from 'buttered';
import { WIDTH, PADDING } from './constants';

export let List = styled('ul')`
  list-style: none;
  margin: 0px;
  padding: 0px;
  display: flex;
  width: fit-content;
  z-index: 2;
  position: relative;
`;

export let Option = styled('li')`
  width: ${WIDTH}px;
  text-align: center;
  height: 26px;
  font-size: 14px;
  cursor: pointer;
  outline: none;
  color: var(--vapor-accent-7);
  transition: all 0.3s;

  &.isFocused {
    color: var(--vapor-primary);
  }

  &.isSelected {
    color: var(--vapor-primary);
  }
`;

export let Wrapper = styled('nav')`
  background: var(--vapor-accent-1);
  width: fit-content;
  padding: ${PADDING}px;
  border-radius: var(--vapor-radius-big);
  position: relative;
`;

export let Indicator = styled('div')`
  width: ${WIDTH}px;
  background: white;
  position: absolute;
  top: ${PADDING}px;
  height: 26px;
  border-radius: var(--vapor-radius-medium);
  transition: all 0.3s;
  z-index: 1;
  box-shadow: var(--vapor-shadow-big);
`;
