import * as React from 'react';
import { useCallback } from 'react';
import { keyframes } from 'buttered';
import { styled } from 'buttered';

import { Indicator } from './indicator';
import { AnimatedIconWrapper } from './icon-wrapper';
import { Toast, ToastPosition } from 'react-hot-toast/dist/core/types';

export type ValueFunction<TValue, TArg> = (arg: TArg) => TValue;

export type ValueOrFunction<TValue, TArg> = TValue | ValueFunction<TValue, TArg>;

let isFunction = <TValue, TArg>(
  valOrFunction: ValueOrFunction<TValue, TArg>
): valOrFunction is ValueFunction<TValue, TArg> => typeof valOrFunction === 'function';

export let resolveValueOrFunction = <TValue, TArg>(
  valOrFunction: ValueOrFunction<TValue, TArg>,
  arg: TArg
): TValue => (isFunction(valOrFunction) ? valOrFunction(arg) : valOrFunction);

let enterAnimation = (factor: number) => `
0% {transform: translateY(60px); opacity: 0;}
100% {transform: translateY(0px); opacity: 1;}
`;

let exitAnimation = (factor: number) => `
0% {transform: translateY(0px) scale(1); opacity:1;}
100% {transform: translateY(20px) scale(0.6); opacity:0;}
`;

let ToastBarBase = styled('div')`
  display: flex;
  align-items: center;
  background: var(--vapor-black);
  color: var(--vapor-white);
  line-height: 1.3;
  will-change: transform;
  box-shadow: var(--vapor-shadow-smaller);
  max-width: 350px;
  width: calc(100vw - 20px);
  margin: 16px;
  pointer-events: auto;
  padding: 16px 18px;
  border-radius: 8px;

  &.success {
    background: var(--vapor-primary);
    color: var(--vapor-primary-text);
  }

  &.error {
    background: var(--vapor-error);
    color: var(--vapor-error-text);
  }
`;

let Message = styled('div')`
  display: flex;
  margin: 4px 10px;
  color: inherit;
  flex: 1;
`;

interface ToastBarProps {
  toast: Toast;
  offset: number;
  onHeight: (height: number) => void;

  position: ToastPosition;
}

let getPositionStyle = (offset: number): React.CSSProperties => {
  return {
    position: 'fixed',
    transition: 'all 600ms cubic-bezier(.21,1.02,.73,1)',
    transform: `translateY(${-offset}px)`,
    right: 0,
    bottom: 0
  };
};

let getAnimationStyle = (visible: boolean): React.CSSProperties => {
  return visible
    ? {
        animation: `${keyframes`${enterAnimation(
          -1
        )}`} 0.8s cubic-bezier(.21,1.02,.73,1) forwards`
      }
    : {
        animation: `${keyframes`${exitAnimation(
          -1
        )}`} 0.6s forwards cubic-bezier(.06,.71,.55,1)`,
        pointerEvents: 'none'
      };
};

export let ToastBar: React.FC<ToastBarProps> = React.memo(({ toast, position, ...props }) => {
  let ref = useCallback((el: HTMLElement | null) => {
    if (el) {
      setTimeout(() => {
        let boundingRect = el.getBoundingClientRect();
        props.onHeight(boundingRect.height);
      });
    }
  }, []);

  let positionStyle = getPositionStyle(props.offset);
  let animationStyle = toast?.height ? getAnimationStyle(toast.visible) : { opacity: 0 };

  let renderIcon = () => {
    let { icon, type, iconTheme } = toast;
    if (icon !== undefined) {
      if (typeof icon === 'string') {
        return <AnimatedIconWrapper>{icon}</AnimatedIconWrapper>;
      } else {
        return icon;
      }
    }

    return <Indicator theme={iconTheme} type={type} />;
  };

  return (
    <div
      style={{
        display: 'flex',
        zIndex: toast.visible ? 9999 : undefined,
        pointerEvents: 'none',
        ...positionStyle
      }}
    >
      <ToastBarBase
        ref={ref}
        className={toast.className + ' ' + toast.type}
        style={{
          pointerEvents: 'initial',
          ...animationStyle,
          ...toast.style
        }}
      >
        {renderIcon()}
        <Message role={toast.role} aria-live={toast.ariaLive}>
          {resolveValueOrFunction(toast.message, toast)}
        </Message>
      </ToastBarBase>
    </div>
  );
});
