import React from 'react';
import { keyframes, styled } from 'buttered';
import { useProgressBar } from '@react-aria/progress';

let spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  
  100% {
    transform: rotate(360deg);
  }
`;

let DEFAULT_SIZE = 20;

let getBorderSize = (size: number) => size * 0.16;

let Wrapper = styled('div')<{ size: number; color?: string }>`
  border: ${p => getBorderSize(p.size)}px solid var(--vapor-accent-2);
  border-top: ${p =>
    getBorderSize(p.size) + 'px solid ' + (p.color || 'var(--vapor-foreground)')};
  border-radius: 50%;
  width: ${p => p.size}px;
  height: ${p => p.size}px;
  animation: ${spin} 2s linear infinite;
`;

export let Spinner = ({ size, color }: { size?: number; color?: string }) => {
  let { progressBarProps } = useProgressBar({
    isIndeterminate: true,
    'aria-label': 'Loading...'
  });

  return <Wrapper {...progressBarProps} size={size || DEFAULT_SIZE} color={color} />;
};
