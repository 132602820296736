import React from 'react';
import { VerticalCenter } from '../../../atoms/align/verticalCenter';
import { styled } from 'buttered';

let Wrapper = styled('div')`
  display: flex;
`;

let Icon = styled('aside')`
  flex-shrink: 0;
  margin-right: 10px;
`;

let Content = styled('main')``;

let Title = styled('h3')`
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
`;

let Description = styled('p')`
  font-size: 11px;
  font-weight: 400;
  color: var(--vapor-accent-7);
  margin-top: 3px;
  line-height: 1;
`;

export let MenuEntity = ({
  icon,
  title,
  description
}: {
  icon?: React.ReactNode;
  title: React.ReactNode;
  description?: React.ReactNode;
}) => {
  return (
    <Wrapper>
      <div>
        <VerticalCenter>{icon && <Icon>{icon}</Icon>}</VerticalCenter>
      </div>

      <Content>
        <VerticalCenter>
          <div>
            <Title>{title || 'Empty'}</Title>

            {description && <Description>{description}</Description>}
          </div>
        </VerticalCenter>
      </Content>
    </Wrapper>
  );
};
