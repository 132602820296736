import { styled } from 'buttered';

export let Snippet = styled('pre')`
  text-align: left;
  margin: 1em 0;
  padding: 20px 24px;
  overflow: auto;
  font-size: 13px;
  line-height: 1.5;
  border: var(--vapor-border);
  border-radius: var(--vapor-radius-big);
  font-family: 'Overpass Mono', Menlo, Monaco, 'Lucida Console', 'Liberation Mono',
    'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', 'Courier New', monospace, serif; ;
`;
