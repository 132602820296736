import React, { useMemo } from 'react';
import { Separator } from '../components/separator';
import { Option } from './option';
import { SelectionList } from './selectionList';
import { renderItem } from './renderItem';
import { Popover, usePopoverState } from '../../popover';

export type MenuItem = {
  id: string;
  type: 'separator' | 'item';
  disabled?: boolean;
  component?: React.ReactNode;
};

export let MenuWrapper = ({
  label,
  children,
  action,
  minWidth,
  items
}: {
  label: string;
  children: (d: {
    attrs: React.HTMLAttributes<HTMLElement> & {
      'data-popover-anchor': string;
    };
    ref: React.RefObject<any>;
  }) => React.ReactNode;
  action: (id: string) => unknown;
  minWidth?: number;
  items: MenuItem[];
}) => {
  let popoverState = usePopoverState();

  let filteredItems = useMemo(
    () =>
      items?.filter(i => {
        return i.type == 'separator' || (i.type == 'item' && i.component);
      }),
    [items]
  );

  if (!Array.isArray(items)) {
    throw new Error('Vapor Menu: Missing items');
  }

  if (typeof children != 'function') {
    throw new Error('Vapor Menu: Children must be a function');
  }

  return (
    <Popover
      mobile={{
        type: 'drawer'
      }}
      state={popoverState}
      styles={{
        popoverInner: {
          padding: '6px'
        }
      }}
      popover={() => (
        <div
          style={{
            minWidth: minWidth ? `min(${minWidth}px, 95vw)` : undefined
          }}
        >
          <SelectionList
            onClick={id => {
              action(id);
              popoverState.close();
            }}
            items={filteredItems.map(item => ({
              id: item.id,
              component: ({ selected }) =>
                item.type == 'separator' ? (
                  <Separator />
                ) : (
                  <Option selected={selected}>{renderItem(item)}</Option>
                ),
              disabled: item.disabled || item.type == 'separator'
            }))}
          />
        </div>
      )}
    >
      {({ ref, attrs }) => children({ ref, attrs })}
    </Popover>
  );
};
