import React, { useCallback, useMemo } from 'react';

export type Triggers =
  | 'click'
  | 'focus'
  | 'hover'
  | 'hover-in'
  | 'hover-out'
  | 'focus-in'
  | 'focus-out';

export let useTrigger = (
  triggers: Triggers[],
  action: (data: {
    type: Triggers;
    event: React.MouseEvent | React.FocusEvent;
    intent?: 'open' | 'close';
  }) => unknown
) => {
  let onClick = useCallback(
    (event: React.MouseEvent) => {
      action({
        type: 'click',
        event
      });
    },
    [action]
  );

  let onFocus = useCallback(
    (event: React.FocusEvent) => {
      action({
        type: 'focus',
        event,
        intent: 'open'
      });
    },
    [action]
  );

  let onBlur = useCallback(
    (event: React.FocusEvent) => {
      action({
        type: 'focus',
        event,
        intent: 'close'
      });
    },
    [action]
  );

  let onMouseEnter = useCallback(
    (event: React.FocusEvent) => {
      action({
        type: 'hover',
        event,
        intent: 'open'
      });
    },
    [action]
  );

  let onMouseLeave = useCallback(
    (event: React.FocusEvent) => {
      action({
        type: 'hover',
        event,
        intent: 'close'
      });
    },
    [action]
  );

  let listeners = useMemo(() => {
    let listeners: any = {};

    if (triggers.includes('click')) {
      listeners['onClick'] = onClick;
    }

    if (triggers.includes('focus')) {
      listeners['onFocus'] = onFocus;
      listeners['onBlur'] = onBlur;
    }

    if (triggers.includes('hover')) {
      listeners['onMouseEnter'] = onMouseEnter;
      listeners['onMouseLeave'] = onMouseLeave;
    }

    if (triggers.includes('focus-in')) {
      listeners['onFocus'] = onFocus;
    }

    if (triggers.includes('focus-out')) {
      listeners['onBlur'] = onBlur;
    }

    if (triggers.includes('hover-in')) {
      listeners['onMouseEnter'] = onMouseEnter;
    }

    if (triggers.includes('hover-out')) {
      listeners['onMouseLeave'] = onMouseLeave;
    }

    return listeners;
  }, [triggers, onClick, onFocus, onBlur, onMouseEnter, onMouseLeave]);

  return listeners;
};
