import React from 'react';
import { styled } from 'buttered';
import { VerticalCenter } from '../align/verticalCenter';

let Wrapper = styled(VerticalCenter)`
  height: auto;
`;

let Inner = styled('div')`
  font-size: 14px;
  padding: 3px 6px;
  border-radius: var(--vapor-radius-small);
`;

export let Badge = ({
  children,
  color,
  textColor
}: {
  children: React.ReactNode;
  color?: string;
  textColor?: string;
}) => {
  return (
    <Wrapper>
      <Inner
        style={{
          background: color || 'var(--vapor-primary)',
          color: textColor || 'var(--vapor-primary-text)'
        }}
      >
        {children}
      </Inner>
    </Wrapper>
  );
};
