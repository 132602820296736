import React from 'react';
import { getAnimation } from '../utils/animation';

export let getPopoverStyles = ({
  styleOverrides,
  styleOverridesMobile,
  isOpen,
  cssVarPrefix,
  cssVarPrefixMobile,
  isMobile,
  unstyled,
  mobileType
}: {
  styleOverrides?: React.CSSProperties;
  styleOverridesMobile?: React.CSSProperties;
  isOpen: boolean;
  cssVarPrefix: string;
  cssVarPrefixMobile: string;
  isMobile: boolean;
  unstyled?: boolean;
  mobileType: string;
}) => {
  let style: React.CSSProperties = {
    animation: getAnimation(isOpen, cssVarPrefix)
  };

  if (!unstyled) {
    if (isMobile) {
      style = Object.assign(
        style,
        {
          borderRadius: `var(${cssVarPrefixMobile}-radius, 12px)`,
          background: `var(${cssVarPrefixMobile}-background, white)`,
          color: `var(${cssVarPrefixMobile}-color, black)`,
          overflow: 'auto',
          maxHeight: `var(${cssVarPrefixMobile}-height, 66vh)`
        },
        mobileType == 'drawer'
          ? {
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
              paddingBottom: 'env(safe-area-inset-bottom)'
            }
          : undefined
      );
    } else {
      style = Object.assign(style, {
        padding: `var(${cssVarPrefix}-padding, 15px)`,
        borderRadius: `var(${cssVarPrefix}-radius, 12px)`,
        border: `var(${cssVarPrefix}-border, solid #efefef 1px)`,
        background: `var(${cssVarPrefix}-background, white)`,
        color: `var(${cssVarPrefix}-color, black)`,
        boxShadow: `var(${cssVarPrefix}-shadow, 0px 4px 8px rgba(0, 0, 0, 0.12))`
      });
    }
  }

  if (styleOverrides && !isMobile) style = Object.assign(style, styleOverrides);

  if (styleOverridesMobile && isMobile) style = Object.assign(style, styleOverridesMobile);

  return style;
};
