import { styled } from 'buttered';

export let LI = styled('li')`
  font-size: 1em;
  line-height: 1.625em;
  margin-bottom: 10px;
  color: var(--vapor-foreground);
`;

export let UL = styled('ul')`
  margin: 10px 10px 10px 30px;

  li {
    &:before {
      content: '–';
      display: inline-block;
      position: absolute;
      margin-left: -20px;
    }
  }
`;

export let OL = styled('ol')`
  padding: 0px;
  margin: 10px 10px 10px 30px;
`;
