import { createContext, useContext } from 'react';

let ModalContext = createContext<{
  type: 'sidebar' | 'drawer' | 'dialog';
  onClose: () => void;
  isOpen: boolean;
  isMenu: boolean;
  isMobile: boolean;
}>({ type: 'dialog', onClose: () => {}, isOpen: false, isMenu: false, isMobile: false });

export let ModalProvider = ModalContext.Provider;

export let useModalContext = () => useContext(ModalContext);
