import {
  useOverlay,
  usePreventScroll,
  useModal,
  OverlayContainer
} from '@react-aria/overlays';
import { useDialog } from '@react-aria/dialog';
import { FocusScope } from '@react-aria/focus';
import React, { useRef } from 'react';
import { useIsSSR } from '@react-aria/ssr';
import useDelayed from 'use-delayed';
import { styled } from 'buttered';
import { isIOS, mergeProps } from '@react-aria/utils';
import { useWindowSize } from 'react-use';
import { Blanket } from './blanket';
import {
  showModalCenteredKeyframes,
  hideModalCenteredKeyframes,
  showModalDrawerKeyframes,
  hideModalDrawerKeyframes,
  showModalSidebarKeyframes,
  hideModalSidebarKeyframes
} from './keyframes';
import { ModalProvider } from './context';
import { useCss } from '../../hooks/css';
import { Spacer } from '../../atoms/position/spacer';

let Outer = styled('div')`
  position: fixed;
  z-index: 1000;
  display: flex;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
`;

let useOuterStyles = (type: 'sidebar' | 'drawer' | 'dialog', isOpen: boolean) => {
  let specificStyleObject = '';

  if (type == 'dialog') {
    specificStyleObject = `    
      align-items: center;
      justify-content: center;
    `;

    if (isOpen) {
      specificStyleObject += `
        animation: ${showModalCenteredKeyframes} 0.45s ease forwards;
      `;
    } else {
      specificStyleObject += `
        animation: ${hideModalCenteredKeyframes} 0.4s ease forwards;
      `;
    }
  }

  if (type == 'drawer') {
    specificStyleObject = `
      flex-direction: column;
    `;

    if (isOpen) {
      specificStyleObject += `
        animation: ${showModalDrawerKeyframes} 0.25s ease forwards;
      `;
    } else {
      specificStyleObject += `
        animation: ${hideModalDrawerKeyframes} 0.3s ease forwards;
      `;
    }
  }

  if (type == 'sidebar') {
    specificStyleObject = `    
      justify-content: flex-end;
    `;

    if (isOpen) {
      specificStyleObject += `
        animation: ${showModalSidebarKeyframes} 0.45s ease forwards;
      `;
    } else {
      specificStyleObject += `
        animation: ${hideModalSidebarKeyframes} 0.4s ease forwards;
      `;
    }
  }

  return useCss(specificStyleObject);
};

let Wrapper = styled('div')`
  background: var(--vapor-background);
  outline: none;
  overflow-y: auto;
  overflow-x: hidden;
  max-width: 100vw;
  max-height: 100vh;
`;

let useWrapperStyles = ({
  isMobile,
  isMenu,
  isDrawer,
  isDialog,
  isSidebar,
  disableBlanket
}: {
  isMobile: boolean;
  isMenu: boolean;
  isDrawer: boolean;
  isDialog: boolean;
  isSidebar: boolean;
  disableBlanket: boolean;
}) => {
  let styles = '';

  if (isMobile && isMenu) {
    styles = `
      border-radius: 0px !important;
      position: fixed;
      top: 0px;
      left: 0px;
      bottom: 0px;
      z-index: 1001;
    `;
  } else {
    if (isDrawer) {
      styles = `
        width: 100vw;
        border-top-left-radius: var(--vapor-radius-biggest);
        border-top-right-radius: var(--vapor-radius-biggest);
        padding-bottom: env(safe-area-inset-bottom);
        max-height: calc(86vh - 50px);
      `;
    } else if (isSidebar) {
      styles = `
        height: 100vh;
        border-top-left-radius: var(--vapor-radius-biggest);
        border-bottom-left-radius: var(--vapor-radius-biggest);
      `;
    } else if (isDialog) {
      styles = `border-radius: var(--vapor-radius-biggest);`;

      if (disableBlanket) {
        styles += 'border: var(--vapor-border);';
      }
    }
  }

  if (disableBlanket) {
    styles += `
      box-shadow: var(--vapor-shadow-big);
    `;
  }

  return useCss(styles);
};

let Inner = styled('section')`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

let IOSBottomSpacer = styled('div')`
  height: env(safe-area-inset-bottom);
  background: var(--vapor-background);
  position: fixed;
  z-index: 1002;
  bottom: 0px;
  left: 0px;
  right: 0px;
`;

export let ModalWrapper = (props: {
  label: string;
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => unknown;
  isDismissable?: boolean;
  role?: 'dialog' | 'alertdialog';
  type?: 'sidebar' | 'drawer' | 'dialog';
  width?: number;
  height?: number;
  interactive?: boolean;
  disableBlanket?: boolean;
}) => {
  let { label, children, isOpen, type, width, height, onClose, disableBlanket } = props;

  let ref = useRef();
  let { overlayProps } = useOverlay(Object.assign({ isDismissable: true }, props), ref);

  let render = useDelayed(isOpen, 400, [true]);
  let isSSR = useIsSSR();

  let { width: windowWidth, height: windowHeight } = useWindowSize();

  if (!type) type = 'dialog';

  let isDrawer = type == 'drawer' || windowWidth < 500;
  let isMobile = windowWidth < 500;

  let isDialog = !isDrawer && type == 'dialog';
  let isSidebar = !isDrawer && type == 'sidebar';

  if (typeof width == 'number' && width > windowWidth) width = windowWidth;

  if (typeof height == 'number' && height > windowHeight) height = windowHeight;

  let isMenu =
    !Array.isArray(children) && Array.isArray((children as any)?.props?.state?.options);

  if (isMenu && (!width || width < 700)) width = 700;

  usePreventScroll({
    isDisabled: !render || (props.interactive && (isMobile || isIOS()))
  });

  let { modalProps } = useModal();
  let { dialogProps } = useDialog(Object.assign({}, props, { 'aria-label': label }), ref);

  let outerStyles = useOuterStyles(isDrawer ? 'drawer' : type, isOpen);
  let wrapperStyles = useWrapperStyles({
    disableBlanket,
    isMenu,
    isMobile,
    isDialog,
    isDrawer,
    isSidebar
  });

  if (isSSR || !render) return null;

  return (
    <ModalProvider value={{ type, onClose, isOpen, isMenu, isMobile }}>
      <OverlayContainer>
        {!disableBlanket && <Blanket isOpen={isOpen} />}

        <Outer className={outerStyles}>
          {isDrawer && <Spacer />}

          <FocusScope contain restoreFocus autoFocus>
            <Wrapper
              {...mergeProps(overlayProps, dialogProps, modalProps, {
                'aria-hidden': !isOpen ? 'true' : undefined
              })}
              className={wrapperStyles}
              ref={ref}
              style={{
                width: !isMobile ? width : '100vw',
                height: !isMobile ? height : undefined,
                maxWidth: !isMobile ? width : '100vw',
                maxHeight: !isMobile ? height : undefined
              }}
            >
              <Inner>{children}</Inner>

              <IOSBottomSpacer />
            </Wrapper>
          </FocusScope>
        </Outer>
      </OverlayContainer>
    </ModalProvider>
  );
};
