import React from 'react';
import { styled } from 'buttered';
import { classNames } from '../../core/classNames';
import { useImage } from '../../hooks/image';

let Wrapper = styled('div')`
  background: var(--vapor-accent-1);
  border-radius: var(--vapor-radius-big);
  overflow: hidden;
`;

let Inner = styled('div')`
  transition: all 0.3s;
  opacity: 1;

  &.loading {
    opacity: 0;
  }
`;

export let Image = (props: {
  label: string;
  src?: string;
  width: number;
  height: number;
  border?: number;
  radius?: number;
  styles?: {
    wrapper?: React.CSSProperties;
    image?: React.CSSProperties;
  };
}) => {
  let { radius, border } = props;

  if (!border) border = radius;

  let [image, { loaded }] = useImage(
    Object.assign(
      {
        style: Object.assign(
          {
            borderRadius: typeof border == 'number' ? border : 'var(--vapor-radius-big)'
          },
          props.styles?.image
        )
      },
      props
    )
  );

  return (
    <Wrapper
      aria-label={loaded ? undefined : 'Loading...'}
      style={Object.assign({ borderRadius: props.border }, props.styles?.wrapper)}
    >
      <Inner className={classNames({ loading: !loaded })}>{image}</Inner>
    </Wrapper>
  );
};
