import { ButtonStyleVariant, ButtonStyle } from './types';

export let loadingStyles: ButtonStyleVariant = {
  border: 'var(--vapor-accent-4)',
  background: 'var(--vapor-background)',
  color: 'var(--foreground)'
};

export let disabledStyles: ButtonStyleVariant = {
  border: 'var(--vapor-accent-3)',
  background: 'var(--vapor-accent-2)',
  color: 'var(--vapor-accent-5)'
};

export let variants: { [key: string]: ButtonStyle } = {
  default: {
    normal: {
      border: 'transparent',
      background: 'transparent',
      color: 'var(--vapor-accent-7)'
    },
    focus: {
      border: 'var(--vapor-accent-2)',
      background: 'var(--vapor-accent-2)',
      color: 'var(--vapor-foreground)'
    }
  },
  primary: {
    normal: {
      border: 'var(--vapor-primary)',
      background: 'var(--vapor-primary)',
      color: 'var(--vapor-primary-text)'
    },
    focus: {
      border: 'var(--vapor-primary)',
      background: 'var(--background)',
      color: 'var(--vapor-primary)'
    }
  },
  secondary: {
    normal: {
      border: 'var(--vapor-foreground)',
      background: 'var(--vapor-foreground)',
      color: 'var(--vapor-background)'
    },
    focus: {
      border: 'var(--vapor-foreground)',
      background: 'var(--vapor-background)',
      color: 'var(--vapor-foreground)'
    }
  },
  tertiary: {
    normal: {
      border: 'var(--vapor-accent-4)',
      background: 'var(--vapor-background)',
      color: 'var(--vapor-accent-9)'
    },
    focus: {
      border: 'var(--vapor-foreground)',
      background: 'var(--vapor-accent-1)',
      color: 'var(--vapor-foreground)'
    }
  },
  error: {
    normal: {
      border: 'var(--vapor-error)',
      background: 'var(--vapor-error)',
      color: 'var(--vapor-error-text)'
    },
    focus: {
      border: 'var(--vapor-error)',
      background: 'var(--background)',
      color: 'var(--vapor-error)'
    }
  }
};
