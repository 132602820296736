import React from 'react';
import { useSeparator } from '@react-aria/separator';
import { styled } from 'buttered';

let SeparatorWrapper = styled('div')`
  user-select: none;
  pointer-events: none;
  height: 1px;
  background: var(--vapor-accent-4);
  margin: 4px 8px;
`;

export let Separator = () => {
  let { separatorProps } = useSeparator({});
  return <SeparatorWrapper {...separatorProps} />;
};
