import React from 'react';
import { classNames } from '../../core/classNames';
import { styled } from 'buttered';

let Wrapper = styled('div')`
  display: flex;

  &.row {
    flex-direction: row;
    flex-wrap: wrap;
  }

  &.col {
    flex-direction: column;
  }
`;

export let Container = ({
  children,
  row,
  col,
  gap,
  style
}: {
  children: React.ReactNode;
  row?: boolean;
  col?: boolean;
  gap?: string | number;
  style?: React.CSSProperties;
}) => {
  return (
    <Wrapper className={classNames({ row, col })} style={{ ...style, gap }}>
      {children}
    </Wrapper>
  );
};
