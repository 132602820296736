import { useFocus } from '@react-aria/interactions';
import { mergeProps, useId } from '@react-aria/utils';
import React, { useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { classNames } from '../../core/classNames';
import { styled } from 'buttered';
import { Error } from '../text/error';

let FONTSIZE = '16px';

let Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

let Label = styled('label')`
  font-size: 13px;
  margin-bottom: 6px;
  font-weight: 500;
`;

let Description = styled('p')`
  font-size: 13px;
  color: var(--vapor-accent-6);
`;

let Extra = styled('div')`
  margin-top: 6px;
`;

let StyledTextarea = styled(TextareaAutosize)`
  border: none;
  outline: none;
  background: transparent;
  height: 34px;
  padding: 14px 16px;
  font-size: ${FONTSIZE};
  flex-grow: 1;
`;

let InputWrapper = styled('main')`
  border: var(--vapor-border);
  border-radius: var(--vapor-radius-medium);
  transition: all 0.3s;
  display: flex;
  overflow: hidden;

  &.focussed {
    border: var(--vapor-border-highlight);
  }
`;

export let Textarea = React.forwardRef(
  (
    props: {
      value?: string;
      label: string;
      minRows?: number;
      maxRows?: number;
      resize?: boolean;
      error?: React.ReactNode;
      description?: React.ReactNode;
      style?: {
        wrapper?: React.CSSProperties;
        input?: React.CSSProperties;
        inputOuter?: React.CSSProperties;
      };
    } & React.InputHTMLAttributes<HTMLInputElement>,
    innerRef: any
  ) => {
    let labelId = useId();
    let descriptionId = useId();

    let style = props.style || {};

    let [focussed, setFocussed] = useState(false);

    let { focusProps } = useFocus({
      onFocusChange: setFocussed
    });

    return (
      <Wrapper style={style.wrapper}>
        <Label id={labelId}>{props.label}</Label>

        <InputWrapper className={classNames({ focussed })} style={style.inputOuter}>
          <StyledTextarea
            {...mergeProps(props, focusProps)}
            style={
              { ...(style.input || {}), resize: props.resize ? 'vertical' : 'none' } as any
            }
            maxRows={props.maxRows}
            minRows={props.minRows || 4}
            ref={innerRef}
            aria-describedby={!props.description ? descriptionId : undefined}
            aria-labelledby={labelId}
          />
        </InputWrapper>

        {(props.error || props.description) && (
          <Extra>
            <>
              {props.error && (
                <Error style={{ fontSize: 13 }} iconSize={16}>
                  {props.error}
                </Error>
              )}

              {!props.error && props.description && (
                <Description>{props.description}</Description>
              )}
            </>
          </Extra>
        )}
      </Wrapper>
    );
  }
);
