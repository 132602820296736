import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useFocus, useHover } from '@react-aria/interactions';
import useDelayed from 'use-delayed';
import { mergeProps } from '@react-aria/utils';
import { useSkeleton } from '../../hooks/skeleton';
import { useCss } from '../../hooks/css';
import { classNames } from '../../core/classNames';
import { getCss, getHeight, Wrapper, IconWrapper, Inner, LoadingWrapper } from './styles';
import { ButtonSizes, ButtonStyle, ButtonVariants } from './types';
import { Spinner } from '../loading/spinner';
import { Spacer } from '../position/spacer';
import { Skeleton } from '../skeleton';

export * from './types';

export let Button = React.forwardRef(
  (
    props: {
      size?: ButtonSizes;
      icon?: React.ReactElement;
      iconRight?: React.ReactElement;
      variant?: ButtonVariants | ButtonStyle;
      loading?: boolean;
      skeleton?: boolean;
      disabled?: boolean;
      fullWidth?: boolean;
      rounded?: boolean;
      children?: React.ReactNode;
      onHoverChange?: (hover: boolean) => unknown;
      onFocusChange?: (focus: boolean) => unknown;
      iconRotation?: number;
      onPress?: React.MouseEventHandler<HTMLButtonElement>;
    } & React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >,
    innerRef
  ) => {
    let {
      size,
      icon,
      iconRight,
      variant,
      loading,
      skeleton,
      rounded,
      children,
      disabled,
      onFocusChange,
      onHoverChange,
      iconRotation,
      fullWidth
    } = props;

    let [isFocussed, setFocussed] = useState(false);
    let treeSkeleton = useSkeleton();

    let ref = useRef();

    if (innerRef && typeof innerRef != 'function') {
      (innerRef as any).current = ref.current;
    }

    useEffect(() => {
      if (innerRef) {
        if (typeof innerRef == 'function') innerRef(ref.current);
        else (innerRef as any).current = ref.current;
      }
    }, [innerRef, ref.current]);

    let { hoverProps, isHovered } = useHover({
      onHoverChange
    });

    let { focusProps } = useFocus({
      onFocusChange: focus => {
        setFocussed(focus);
        if (onFocusChange) onFocusChange(focus);
      }
    });

    if (!size) size = 'medium';
    if (!variant) variant = 'default';

    let style = useMemo(() => getCss(variant, { isHovered, isFocussed, disabled, loading }), [
      isHovered,
      isFocussed,
      disabled,
      loading,
      variant
    ]);

    let className = useCss(style);

    let dimensions = useMemo(() => {
      let height = getHeight(variant, size);

      return {
        height,
        padding: height && `0px ${Math.ceil(height * 0.35)}px`,
        fontSize: height && Math.ceil(height * 0.43),
        iconPadding: height && Math.ceil(height * 0.15)
      };
    }, [size]);

    let radius = useMemo(() => {
      if (!dimensions.height) return undefined;
      if (rounded) return 100;
      return Math.ceil(dimensions.height / 5);
    }, [rounded, dimensions.height]);

    let loadingVisible = useDelayed(loading, 500, [true]);

    let iconOnly = icon && !children;

    return (
      <Skeleton
        loading={skeleton || treeSkeleton}
        radius={radius || 4}
        style={{
          width: fullWidth ? '100%' : undefined
        }}
      >
        <Wrapper
          {...(mergeProps(
            props,
            hoverProps,
            focusProps,
            props.onPress ? { onClick: props.onPress } : {}
          ) as any)}
          ref={ref}
          style={{
            height: dimensions.height,
            padding: !iconOnly ? dimensions.padding : 0,
            width: fullWidth ? '100%' : iconOnly ? dimensions.height : undefined,
            fontSize: dimensions.fontSize,
            borderRadius: radius,
            ...props.style
          }}
          className={className}
        >
          {icon && (
            <IconWrapper
              className={classNames({ smaller: size == 'smaller' })}
              style={{
                transform: iconRotation ? `rotate(${iconRotation}deg)` : undefined,
                ...(iconOnly ? { width: '100%', alignItems: 'center' } : {})
              }}
            >
              <figure>{icon}</figure>
            </IconWrapper>
          )}

          {icon && children && <Spacer width={dimensions.iconPadding} />}

          {children && <Inner>{children}</Inner>}

          {iconRight && <Spacer width={dimensions.iconPadding} />}

          {iconRight && (
            <IconWrapper className={classNames({ smaller: size == 'smaller' })}>
              <figure>{iconRight}</figure>
            </IconWrapper>
          )}

          {loadingVisible && (
            <LoadingWrapper
              className={classNames({ visible: loading })}
              aria-hidden={loading ? 'false' : 'true'}
            >
              <Spinner size={16} />
            </LoadingWrapper>
          )}
        </Wrapper>
      </Skeleton>
    );
  }
);
