import React from 'react';
import { getAnimation } from '../utils/animation';

let cssVarPrefix = '--vapor-popover-blanket';

export let Blanket = ({
  visible,
  zIndex,
  onClick
}: {
  visible: boolean;
  zIndex: number;
  onClick: () => void;
}) => {
  return (
    <aside
      onClick={onClick}
      style={{
        position: 'fixed',
        zIndex: zIndex,
        background: `var(${cssVarPrefix}-background, rgba(80, 80, 80, 0.4))`,
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        animation: getAnimation(visible, cssVarPrefix, true),
        pointerEvents: visible ? 'all' : 'none'
      }}
    ></aside>
  );
};
