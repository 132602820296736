import { useEffect, useState } from 'react';

export let useIsSmallScreen = () => {
  let [isSmallScreen, setIsSmallScreen] = useState(() => {
    if (typeof window == 'undefined') return false;
    return window.innerWidth < 600;
  });

  useEffect(() => {
    if (typeof window == 'undefined') return;

    let handler = () => setIsSmallScreen(window.innerWidth < 600);

    window.addEventListener('resize', handler);
    return () => window.removeEventListener('resize', handler);
  }, []);

  return isSmallScreen;
};
