import React from 'react';
import { styled } from 'buttered';
import { Image } from '../../../ui/image';

let Wrapper = styled('div')`
  overflow: hidden;
  max-height: 40vh;
  flex-shrink: 0;
`;

export let ModalImageHeader = (props: {
  label: string;
  src?: string;
  width: number;
  height: number;
  styles?: {
    wrapper?: React.CSSProperties;
    image?: React.CSSProperties;
  };
}) => {
  return (
    <Wrapper>
      <Image {...props} border={0} />
    </Wrapper>
  );
};
