import { styled } from 'buttered';

export let EntityFooter = styled('footer')`
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
  justify-content: flex-start;
  align-items: stretch;
  border-top: var(--vapor-border);
`;
