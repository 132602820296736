import React from 'react';
import { styled } from 'buttered';
import { SkeletonProvider } from '../../hooks/skeleton';

let Wrapper = styled('section')`
  border-radius: var(--vapor-radius-bigger);
  border: var(--vapor-border);
  display: flex;
  flex-direction: column;
  position: relative;
`;

export let EntityWrapper = ({
  children,
  style,
  shadow,
  skeleton
}: {
  children: React.ReactNode;
  style?: React.CSSProperties;
  shadow?: boolean;
  skeleton?: boolean;
}) => {
  return (
    <SkeletonProvider value={!!skeleton}>
      <Wrapper
        style={{ boxShadow: shadow ? 'var(--vapor-shadow-medium)' : undefined, ...style }}
      >
        {children}
      </Wrapper>
    </SkeletonProvider>
  );
};
