import React from 'react';
import { CloseIcon } from './closeIcon';

export let MobileHeader = ({
  cssVarPrefix,
  title,
  close,
  closeButton
}: {
  cssVarPrefix: string;
  title: string;
  close: () => void;
  closeButton?: boolean;
}) => {
  return (
    <header
      style={{
        display: 'flex',
        padding: `var(${cssVarPrefix}-header-padding, 5px 5px 5px 20px)`,
        borderBottom: `var(${cssVarPrefix}-header-border, solid #efefef 1px)`,
        background: `var(${cssVarPrefix}-header-background, white)`,
        position: 'sticky',
        top: 0
      }}
    >
      <p
        style={{
          margin: '0px',
          fontSize: `var(${cssVarPrefix}-header-fontsize, 14px)`,
          // @ts-ignore
          fontWeight: `var(${cssVarPrefix}-header-fontweight, 400)`,
          height: 40,
          lineHeight: '40px',
          flexGrow: 1,
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap'
        }}
      >
        {title}
      </p>

      {closeButton && (
        <button
          style={{
            height: 40,
            width: 40,
            padding: 12,
            marginLeft: 5,
            flexShrink: 0,

            background: `var(${cssVarPrefix}-button-background, none)`,
            outline: `var(${cssVarPrefix}-button-outline, none)`,
            border: `var(${cssVarPrefix}-button-border, none)`,

            color: `var(${cssVarPrefix}-button-color, #888)`
          }}
          onClick={close}
        >
          <CloseIcon />
        </button>
      )}
    </header>
  );
};
