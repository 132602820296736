import React from 'react';
import { styled } from 'buttered';
import { VerticalCenter } from '../../../atoms/align/verticalCenter';
import { Spacer } from '../../../atoms/position/spacer';
import { classNames } from '../../../core/classNames';
import { useModalContext } from '../context';
import { ModalCloseButton } from './closeButton';

let Wrapper = styled('header')`
  padding: 15px 20px;
  display: flex;
  font-weight: 600;
  font-size: 16px;
  max-width: 100vw;
  position: sticky;
  top: 0px;
  background: var(--vapor-background);

  &.fluid {
    padding-bottom: 0px;
  }

  &:not(.fluid) {
    border-bottom: var(--vapor-border);

    &:not(.noMargin) {
      margin-bottom: 5px;
    }
  }
`;

let Content = styled('div')`
  flex-grow: 1;
`;

export let ModalHeader = ({
  children,
  fluid,
  noMargin,
  prefix
}: {
  children: React.ReactNode;
  fluid?: boolean;
  noMargin?: boolean;
  prefix?: React.ReactNode;
}) => {
  let modal = useModalContext();
  let styles: React.CSSProperties = {};

  if (!(modal.isMenu && modal.isMobile)) {
    if (modal.type == 'dialog' || modal.type == 'drawer') {
      styles = {
        borderTopLeftRadius: 'var(--vapor-radius-biggest)',
        borderTopRightRadius: 'var(--vapor-radius-biggest)'
      };
    }

    if (modal.type == 'sidebar') {
      styles = {
        borderTopLeftRadius: 'var(--vapor-radius-biggest)'
      };
    }
  }

  return (
    <Wrapper className={classNames({ fluid, noMargin })} style={styles}>
      {prefix && (
        <>
          <div style={{ flexShrink: 0 }}>{prefix}</div>
          <Spacer width={10} />
        </>
      )}

      <Content style={{ flexShrink: 1 }}>
        <VerticalCenter>
          <div
            style={{
              textAlign: prefix ? 'center' : undefined,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              maxWidth: `calc(100vw - ${40 + 40 + (prefix ? 40 : 0)}px)`
            }}
          >
            {children}
          </div>
        </VerticalCenter>
      </Content>

      <Spacer width={10} />

      <div style={{ flexShrink: 0 }}>
        <ModalCloseButton inline />
      </div>
    </Wrapper>
  );
};
