import React from 'react';
import { styled } from 'buttered';
import { MenuItem } from './wrapper';

let Disabled = styled('div')`
  opacity: 0.6;
  pointer-events: none;
`;

export let renderItem = (item: MenuItem) => {
  if (item.disabled) {
    return <Disabled>{item.component}</Disabled>;
  }

  return item.component;
};
