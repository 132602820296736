import { useCallback, useEffect, useRef, useState } from 'react';
import useDelayed from 'use-delayed';
import { getZIndex } from '../utils/getZIndex';
import { useIsSmallScreen } from './useIsSmallScreen';

export let usePopoverState = (props?: {
  isOpen?: boolean;
  openDelay?: number;
  closeDelay?: number;
}) => {
  let [zIndex, setZIndex] = useState(0);
  let [isOpen, internalSetOpen] = useState(props?.isOpen || false);
  let isOpenRef = useRef(false);
  let isVisible = useDelayed(isOpen, 500, [true]);

  let openDelayTimeoutRef = useRef<number | undefined>();
  let closeDelayTimeoutRef = useRef<number | undefined>();
  let isSmallScreen = useIsSmallScreen();

  let delay = props?.openDelay;

  let setOpen = useCallback(
    (
      open: boolean,
      opts: {
        openDelayOverride?: number;
        closeDelayOverride?: number;
      } = {}
    ) => {
      let clearTimeouts = () => {
        if (typeof openDelayTimeoutRef.current != 'undefined') {
          clearTimeout(openDelayTimeoutRef.current);
          openDelayTimeoutRef.current = undefined;
        }

        if (typeof closeDelayTimeoutRef.current != 'undefined') {
          clearTimeout(closeDelayTimeoutRef.current);
          closeDelayTimeoutRef.current = undefined;
        }
      };

      let openHandler = () => {
        if (open) setZIndex(getZIndex(zIndex));
        internalSetOpen(open);

        clearTimeouts();
      };

      let closeHandler = () => {
        if (open) setZIndex(getZIndex(zIndex));
        internalSetOpen(open);

        clearTimeouts();
      };

      let openDelay =
        typeof opts.openDelayOverride == 'number' ? opts.openDelayOverride : props?.openDelay;

      let closeDelay =
        typeof opts.closeDelayOverride == 'number'
          ? opts.closeDelayOverride
          : props?.closeDelay;

      if (open) {
        if (typeof openDelayTimeoutRef.current != 'undefined') return;

        if (openDelay && !isVisible) {
          openDelayTimeoutRef.current = setTimeout(openHandler, openDelay) as any;
        } else {
          openHandler();
        }
      } else {
        if (closeDelay && isVisible) {
          closeDelayTimeoutRef.current = setTimeout(closeHandler, closeDelay) as any;
        } else {
          closeHandler();
        }
      }
    },
    [internalSetOpen, setZIndex, zIndex, delay, openDelayTimeoutRef, isVisible]
  );

  useEffect(
    () => () => {
      if (typeof openDelayTimeoutRef.current != 'undefined')
        clearTimeout(openDelayTimeoutRef.current);
    },
    []
  );

  if (isOpenRef.current !== isOpen) {
    isOpenRef.current = isOpen;
  }

  let close = useCallback(() => setOpen(false), [setOpen]);
  let open = useCallback(() => setOpen(true), [setOpen]);

  let delayedIsOpen = useDelayed(isOpen, 500, [false]);

  return {
    isOpen,
    setOpen,
    isVisible,
    isOpenRef,
    close,
    open,
    zIndex,
    delayedIsOpen,
    isSmallScreen
  };
};
