import { useButton } from '@react-aria/button';
import React, { useEffect, useRef } from 'react';
import { Menu } from 'react-feather';
import { useWindowSize } from 'react-use';
import { Button } from '../../atoms/button';
import { classNames } from '../../core/classNames';
import { styled } from 'buttered';
import { useToggle } from '../../hooks/state/toggle';
import { useSwitch } from '../../hooks/switch';
import { Blanket } from './blanket';
import { ModalContent } from './components/content';
import { ModalHeader } from './components/header';

let Wrapper = styled('div')`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

let Grid = styled('div')`
  display: grid;
  grid-template-columns: 180px 1fr;
  flex-grow: 1;
  overflow: hidden;

  &.isMobile {
    display: block;
  }
`;

let Top = styled('div')`
  position: relative;
  z-index: 103;
`;

let Sidebar = styled('nav')`
  border-right: var(--vapor-border);
  height: 100%;
  padding: 24px 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &.isMobile {
    width: min(calc(100vw - 30px), 250px);
    position: fixed;
    top: 61px;
    bottom: 0px;
    background: var(--vapor-background);
    left: -20px;
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s;
    z-index: 102;

    &.isOpen {
      left: 0px;
      opacity: 1;
      pointer-events: all;
    }
  }
`;

let OptionWrapper = styled('button')`
  font-size: 14px;
  margin-bottom: 10px;
  background: none;
  border: none;
  outline: none;
  padding: 8px 14px;
  color: var(--vapor-accent-7);
  background: transparent;
  transition: color 0.3s, background 0.3s;
  width: 100%;
  text-align: left;
  border-radius: var(--vapor-radius-medium);

  &:hover,
  &:focus {
    color: var(--vapor-foreground);
    background: var(--vapor-accent-2);
  }

  &.selected {
    color: var(--vapor-primary);
    font-weight: 500;
  }
`;

let Option = ({
  onPress,
  children,
  selected
}: {
  onPress: () => void;
  children: string;
  selected?: boolean;
}) => {
  let ref = useRef();
  let { buttonProps } = useButton(
    {
      onPress,
      children
    },
    ref
  );

  return (
    <OptionWrapper {...buttonProps} ref={ref} className={classNames({ selected })}>
      {children}
    </OptionWrapper>
  );
};

export let ModalMenu = ({
  title,
  children,
  state
}: {
  title: React.ReactNode;
  children: React.ReactNode;
  state: ReturnType<typeof useSwitch>;
}) => {
  let { width: windowWidth } = useWindowSize();
  let isMobile = windowWidth < 500;

  let { isSelected, toggle, set } = useToggle(false);

  useEffect(() => {
    if (!isMobile) set(false);
  }, [isMobile]);

  return (
    <>
      <Wrapper>
        <Top>
          <ModalHeader
            noMargin
            prefix={
              isMobile ? <Button size="small" icon={<Menu />} onPress={toggle} /> : undefined
            }
          >
            {title}
          </ModalHeader>
        </Top>

        <Grid className={classNames({ isMobile })}>
          <Sidebar className={classNames({ isMobile, isOpen: isSelected })}>
            {state.options.map(option => (
              <Option
                key={option.id}
                onPress={() => {
                  state.setOption(option.id);
                  set(false);
                }}
                selected={option.id == state.selectedOption?.option.id}
              >
                {option.label}
              </Option>
            ))}
          </Sidebar>

          <ModalContent style={{ overflow: 'auto' }}>
            <div>{children}</div>
          </ModalContent>
        </Grid>
      </Wrapper>

      {isMobile && <Blanket isOpen={isSelected} onClick={() => set(false)} />}
    </>
  );
};
