import { VisuallyHidden } from '@react-aria/visually-hidden';
import { useToggleState } from '@react-stately/toggle';
import { useFocusRing } from '@react-aria/focus';
import { useSwitch } from '@react-aria/switch';
import React, { useRef } from 'react';
import { styled } from 'buttered';
import { classNames } from '../../core/classNames';

let Indicator = styled('span')`
  display: block;
  height: 18px;
  background: var(--vapor-accent-3);
  width: 30px;
  border-radius: 50px;
  position: relative;
  border: solid transparent 1px;
  transition: all 0.3s;

  &.focussed {
    border: solid var(--vapor-primary) 1px;
  }

  &.isSelected {
    background: var(--vapor-primary);
  }
`;

let Dot = styled('span')`
  border-radius: 50%;
  background: var(--vapor-background);
  box-shadow: var(--vapor-shadow-smaller);
  height: 14px;
  width: 14px;
  position: absolute;
  top: 1px;
  left: 1px;
  transition: all 0.3s;

  &.isSelected {
    left: 13px;
  }
`;

let LabelText = styled('span')`
  padding-left: 10px;
  font-size: 14px;
  user-select: none;
`;

let Label = styled('label')`
  display: flex;
  align-items: center;

  &.disabled {
    opacity: 0.7;
  }
`;

export let Toggle = (props: {
  onChange: (selected: boolean) => unknown;
  isSelected?: boolean;
  defaultSelected?: boolean;
  label: string;
  hideLabel?: boolean;
  disabled?: boolean;
}) => {
  let state = useToggleState(props);
  let ref = useRef();
  let { inputProps } = useSwitch({ ...props, 'aria-label': props.label }, state, ref);
  let { isFocusVisible, focusProps } = useFocusRing();

  return (
    <Label style={{}} className={classNames({ disabled: props.disabled })}>
      <VisuallyHidden>
        <input {...inputProps} {...focusProps} ref={ref} />
      </VisuallyHidden>

      <Indicator
        aria-hidden="true"
        className={classNames({ focussed: isFocusVisible, isSelected: state.isSelected })}
      >
        <Dot className={classNames({ isSelected: state.isSelected })} />
      </Indicator>

      {!props.hideLabel && <LabelText>{props.label}</LabelText>}
    </Label>
  );
};
